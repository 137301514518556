import Axios from 'axios';
import {generalSelector} from 'selectors';

export const F_CONFIG_EVOLUTION_CREATION = 1;
export const F_CONFIG_EVOLUTION_STATUS_CHANGE = 2;

// Endpoint
const EP_OPENAI_TRANSLATE = '/openai/translate';
const EP_OPENAI_GENERATE_PATH_RULES = '/openai/generate-path-rules';
const EP_OPENAI_GENERATE_PATH_RULES_EXPLANATION =
  '/openai/generate-path-rules-explanation';
const EP_OPENAI_GENERATE_EVOLUTION = '/openai/generate-evolution';

// Method
export const translate = ({text, languages}) => {
  const projectId = generalSelector.getProject().uid;

  return Axios.post(EP_OPENAI_TRANSLATE, {text, languages, projectId}).then(
    (response) => response.data
  );
};

export const generatePathRules = ({text}) => {
  const projectId = generalSelector.getProject().uid;

  return Axios.post(EP_OPENAI_GENERATE_PATH_RULES, {text, projectId}).then(
    (response) => response.data
  );
};

export const generatePathRulesExplanation = ({rules}) => {
  const projectId = generalSelector.getProject().uid;

  return Axios.post(EP_OPENAI_GENERATE_PATH_RULES_EXPLANATION, {
    rules,
    projectId,
  }).then((response) => response.data);
};

export const generateEvolution = ({elements, pages}) => {
  const projectId = generalSelector.getProject().uid;

  return Axios.post(EP_OPENAI_GENERATE_EVOLUTION, {
    elements,
    pages,
    projectId,
  }).then((response) => response.data);
};
