import AcrossTabs from 'across-tabs';
import {EVENT_CREATE_EXPERIENCE, EVENT_CREATE_PUSH} from 'amplitude';
import amplitude from 'amplitude-js';
import classnames from 'classnames';
import Alert from 'components/Alert';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import {Modal} from 'components/Modal';
import {toastDanger, toastSuccess} from 'components/Toaster';
import Toggle from 'components/Toggle';
import Tooltip from 'components/Tooltip';
import {BuilderContext} from 'contextes/builder';
import {errorHelpers} from 'helpers';
import {hasFlag} from 'helpers/bitwise';
import {
  isChromeExtensionCompatible,
  sendUrlToExtension,
  useIsExtensionInstalled,
} from 'helpers/utils';
import {bool, func, object, string} from 'prop-types';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useQuery} from 'react-query';
import {useSelector} from 'react-redux';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {
  ROUTE_BUILDER_AUDIENCE,
  ROUTE_POKE_BUILDER_FROM_TYPE,
  ROUTE_POKE_SETTINGS_FROM_TYPE,
} from 'router/routes.const';
import {
  MODE_INSTALL,
  MODE_TRY,
} from 'scenes/Onboarding/scenes/Details/step-3-1';
import {getDefaultBlockFromType} from 'scenes/PokeBuilder/components/BlockManager/utils';
import {applyThemeToEvolution} from 'scenes/PokeBuilder/components/ThemeManager/utils';
import {defaultHotspotStyle} from 'scenes/Settings/scenes/Themes/components/ThemeEditor';
import {generalSelector} from 'selectors';
import {buildUrlService, evolutionService} from 'services';
import {
  EVOLUTION_CONTEXT_PORTAL,
  EVOLUTION_TYPE_BANNER,
  EVOLUTION_TYPE_CHECKLIST,
  EVOLUTION_TYPE_HINT,
  EVOLUTION_TYPE_RESOURCE_CENTER,
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
  F_BOOST_SLOT_CURSOR,
  F_BOOST_SLOT_DOT,
  F_BOOST_SLOT_HINT,
  F_BOOST_SLOT_NAVIGATION,
  F_BOOST_SLOT_POP_IN,
  F_BOOST_SLOT_SNIPPET,
  F_BOOST_SLOT_TOOLTIP,
  F_BOOST_SLOT_TOP_BAR,
  F_BOOST_SLOT_TOUR,
  getDefaultEvolution,
  PAGE_ACTIVE_OPERATOR_SINGLE_URL,
} from 'services/evolution';
import {
  BLOCK_TYPE_PRIMARY_CTA,
  BLOCK_TYPE_TITLE,
  getDefaultStep,
  getDefaultStepStyle,
} from 'services/steps';
import {RESOURCE_CENTER_TRIGGER_POSITION_RIGHT} from 'shared/front/components/ResourceCenter/constants';
import {Swaler} from 'swaler';
import {v4 as uuidv4} from 'uuid';
import {TYPE_SURVEY} from '../NewPushModal';
import './_Styles.scss';
import InstallJimo from './components/InstallJimo';
import PickBoostType from './components/PickBoostType';
import TemplateDetails from './components/TemplateDetails';
import TemplatesModal from './components/TemplatesModal';
import {
  TYPE_BANNER,
  TYPE_CURSOR,
  TYPE_HINT,
  TYPE_HOTSPOT,
  TYPE_MODAL,
  TYPE_NAVIGATION,
  TYPE_SNIPPET,
  TYPE_TOOLTIP,
  TYPE_TOUR,
} from './components/TemplatesModal/templates';
import {
  createStepFromPost,
  getDefaultChecklistSteps,
  getDefaultResourceCenterStep,
} from './utils';

export const MODE_PICK_TEMPLATE = 'PICK_TEMPLATE';
export const MODE_PREVIEW = 'PREVIEW';
export const MODE_FORM = 'FORM';
export const MODE_TOUR_GENERATION = 'TOUR_GENERATION';
export const MODE_EDIT = 'EDIT';
export const MODE_INSTALLATION = 'INSTALLATION';
export const MODE_PENDING = 'PENDING';
export const MODE_PICK_BOOST = 'PICK_BOOST';

export const hasQueryParams = (url) => {
  return url.includes('?');
};

export const getBoostFlags = (type) => {
  if (type === TYPE_HOTSPOT) {
    return F_BOOST_SLOT_DOT;
  } else if (type === TYPE_BANNER) {
    return F_BOOST_SLOT_TOP_BAR;
  } else if (type === TYPE_SNIPPET) {
    return F_BOOST_SLOT_SNIPPET;
  } else if (type === TYPE_MODAL) {
    return F_BOOST_SLOT_POP_IN;
  } else if (type === TYPE_TOUR) {
    return F_BOOST_SLOT_TOUR;
  } else if (type === TYPE_TOOLTIP) {
    return F_BOOST_SLOT_TOOLTIP;
  } else if (type === TYPE_NAVIGATION) {
    return F_BOOST_SLOT_NAVIGATION;
  } else if (type === TYPE_SURVEY) {
    return F_BOOST_SLOT_SNIPPET;
  } else if (type === TYPE_HINT) {
    return F_BOOST_SLOT_HINT;
  } else if (type === TYPE_CURSOR) {
    return F_BOOST_SLOT_CURSOR;
  } else {
    return 0;
  }
};

const propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func,
  type: string,
  pokeToEdit: object,
  defaultMode: string,
  defaultType: string,
  defaultTemplate: object,
  resetDefault: func,
  isExtension: bool,
  onCreatedPoke: func,
  previewOnly: bool,
  boostOf: object,
  onBack: func,
  spaceId: string,
};

const logger = new Swaler('ModalCreatePoke');

const ModalCreatePoke = ({
  isOpen,
  onRequestClose = () => {},
  pokeToEdit = null,
  type = null,
  defaultMode = null,
  defaultType = null,
  defaultTemplate = null,
  resetDefault = () => {},
  isExtension = false,
  onCreatedPoke = () => {},
  previewOnly = false,
  boostOf = null,
  onBack = () => {},
  spaceId = null,
  ...rest
}) => {
  const match = useRouteMatch();
  const history = useHistory();

  const project = useSelector((state) => generalSelector.getProject(state));
  const user = useSelector((state) => generalSelector.getUser(state));

  const {evolution, refetchEvolution, currentUrl} =
    useContext(BuilderContext) || {};

  const [forcedType, setForcedType] = useState(null);
  const [url, setUrl] = useState('');
  const [title, setTitle] = useState('');
  const [createdPoke, setCreatedPoke] = useState(null);
  const [pokeType, setPokeType] = useState('');
  const [urlInputFocused, setUrlInputFocused] = useState(false);
  const [mode, setMode] = useState(MODE_PICK_TEMPLATE);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [modeInstall, setModeInstall] = useState(
    isChromeExtensionCompatible ? null : MODE_INSTALL
  );
  const [editInApp, setEditInApp] = useState(false);
  const [boostTypeFlags, setBoostTypeFlags] = useState(null);
  const [isInvalidUrl, setIsInvalidUrl] = useState(false);

  const refParent = useRef();
  const childTabId = useRef();
  const createdEvolutionRef = useRef();
  const previewOnlyRef = useRef();
  const generateTourWithAIRef = useRef(false);

  const isSnippetInstalled = project.snippetInstalledAt != null;
  const {isInstalled: isExtensionInstalled} = useIsExtensionInstalled();

  const hasBuilderAccess =
    isSnippetInstalled === true || isExtensionInstalled === true;

  const displayType =
    selectedTemplate != null
      ? selectedTemplate.experienceType?.toLowerCase()?.replace('_', ' ')
      : forcedType != null
      ? forcedType?.toLowerCase()?.replace('_', ' ')
      : type?.toLowerCase()?.replace('_', ' ');

  useEffect(() => {
    if (
      mode === MODE_TOUR_GENERATION &&
      generateTourWithAIRef.current === false
    ) {
      generateTourWithAIRef.current = true;
    } else if (mode === MODE_FORM && generateTourWithAIRef.current === true) {
      generateTourWithAIRef.current = false;
    }
  }, [mode]);

  useEffect(() => {
    previewOnlyRef.current = previewOnly;
  }, [previewOnly]);

  useEffect(() => {
    if (isInvalidUrl === true) {
      try {
        new URL(url);
        setIsInvalidUrl(false);
      } catch (err) {}
    }
  }, [url]);

  useEffect(() => {
    if (isOpen === false) {
      setSelectedTemplate(null);
      setUrl('');
      setTitle('');
      resetDefault();
    } else {
      if (pokeToEdit != null) {
        if (hasFlag(F_BOOST_SLOT_DOT, pokeToEdit.boostFlags)) {
          setPokeType(TYPE_HOTSPOT);
        }
        if (hasFlag(F_BOOST_SLOT_TOP_BAR, pokeToEdit.boostFlags)) {
          setPokeType(TYPE_BANNER);
        }
        if (hasFlag(F_BOOST_SLOT_SNIPPET, pokeToEdit.boostFlags)) {
          setPokeType(TYPE_SNIPPET);
        }
        if (hasFlag(F_BOOST_SLOT_POP_IN, pokeToEdit.boostFlags)) {
          setPokeType(TYPE_MODAL);
        }
        if (hasFlag(F_BOOST_SLOT_TOOLTIP, pokeToEdit.boostFlags)) {
          setPokeType(TYPE_TOOLTIP);
        }

        setTitle(pokeToEdit.title);
        setUrl(pokeToEdit.boostedActiveUrl);
        setMode(MODE_EDIT);
      } else {
        if (defaultMode != null) {
          setMode(defaultMode);
          setSelectedTemplate(defaultTemplate);
          return;
        }
        if (
          [EVOLUTION_TYPE_CHECKLIST, EVOLUTION_TYPE_RESOURCE_CENTER].includes(
            type
          )
        ) {
          setForcedType(type);
          setMode(MODE_FORM);
        } else {
          setMode(MODE_PICK_TEMPLATE);
        }
      }
    }
  }, [isOpen]);

  useEffect(() => {
    const config = {
      onHandshakeCallback: () => {
        refParent.current?.broadCastTo(childTabId.current, {
          type: 'SET_MODE',
          data: {
            mode:
              generateTourWithAIRef.current === true
                ? 'POKE_TOUR_GENERATION'
                : previewOnlyRef.current === true
                ? 'POKE_PREVIEW'
                : 'POKE_BUILDER',
            pokeId:
              pokeToEdit != null
                ? pokeToEdit.uid
                : createdEvolutionRef.current?.uid,
            project,
          },
        });
      },
      onChildCommunication: (message) => {
        if (message?.type === 'EVOLUTION_SAVED') {
          // using message.data.evolution.context instead of context cause
          // context is null when clicking target & publish in in-app builder
          // which  prevent redirection in the parent tab after closing the in-app builder
          if (
            [
              EVOLUTION_TYPE_TOUR,
              EVOLUTION_TYPE_SURVEY,
              EVOLUTION_TYPE_BANNER,
              EVOLUTION_TYPE_HINT,
            ].includes(message.data.evolution.type)
          ) {
            history.push(
              ROUTE_POKE_SETTINGS_FROM_TYPE(
                message.data.evolution.uid,
                message.data.evolution.type
              )
            );
          } else if (
            message.data.evolution.context === EVOLUTION_CONTEXT_PORTAL
          ) {
            history.push(ROUTE_BUILDER_AUDIENCE(message.data.evolution.uid));
          }
          refParent.current?.closeAllTabs();
        }
        if (message?.type === 'EMBEDDED_BUILDER_CLOSE') {
          closeModal();
        }
        if (message?.type === 'PREVIEW_CLOSE') {
          closeModal();
        }
      },
    };

    const parent = new AcrossTabs.Parent(config);
    refParent.current = parent;
  }, []);

  const {data: buildUrls, refetch: refreshBuildUrls} = useQuery({
    queryKey: 'build-urls',
    queryFn: buildUrlService.getBuildUrls,
    refetchOnWindowFocus: false,
  });

  const closeModal = () => {
    refParent.current?.closeAllTabs();
    setUrl('');
    setCreatedPoke(null);
    onRequestClose();
  };

  const handleCreatePoke = async (e) => {
    e?.preventDefault();

    if (url?.length > 0) {
      try {
        new URL(url);
      } catch (err) {
        setIsInvalidUrl(true);
        return;
      }
    }

    let createdEvolution;

    setIsLoading(true);

    if (url.length > 0) {
      try {
        await buildUrlService.createBuildUrl({url});
      } catch (err) {
        const {code} = errorHelpers.parseError(err);

        logger.error(`Failed creating build url: ${code}`);
      }
    }

    try {
      if (pokeToEdit == null) {
        const themeStepStyle =
          project.defaultTheme?.style?.stepStyle != null
            ? JSON.parse(JSON.stringify(project.defaultTheme?.style?.stepStyle))
            : null;
        delete themeStepStyle?.width;
        delete themeStepStyle?.height;
        const selectedType =
          selectedTemplate?.experienceType || forcedType || type;

        const hotspotStyle =
          project.defaultTheme?.style?.blocksStyle?.['HOTSPOT'];

        let newEvolution = evolutionService.getDefaultEvolution({
          boostFlags:
            boostTypeFlags != null
              ? getBoostFlags(TYPE_TOUR)
              : getBoostFlags(
                  selectedTemplate != null
                    ? selectedTemplate.tourSteps?.length > 0
                      ? TYPE_TOUR
                      : selectedTemplate.experienceType
                    : [
                        EVOLUTION_TYPE_TOUR,
                        EVOLUTION_TYPE_BANNER,
                        EVOLUTION_TYPE_HINT,
                      ].includes(selectedType)
                    ? TYPE_TOUR
                    : TYPE_SNIPPET
                ),
          title,
          ...(selectedTemplate != null
            ? {
                steps:
                  selectedTemplate.steps?.sort(
                    (a, b) => a.indexOrder - b.indexOrder
                  ) || [],
                tourSteps:
                  selectedTemplate.tourSteps
                    ?.sort((a, b) => {
                      const [tourIndexOrderA] = (
                        a.tourStepInfo || '0;0;0'
                      ).split(';');
                      const [tourIndexOrderB] = (
                        b.tourStepInfo || '0;0;0'
                      ).split(';');
                      return tourIndexOrderA - tourIndexOrderB;
                    })
                    .map((s) =>
                      evolutionService.getDefaultEvolution({
                        boostFlags: getBoostFlags(s.type),
                        steps: s.steps.sort(
                          (a, b) => a.indexOrder - b.indexOrder
                        ),
                        style: s.style,
                      })
                    ) || [],
                style: selectedTemplate.style,
              }
            : selectedType === EVOLUTION_TYPE_BANNER
            ? {
                tourSteps: [
                  getDefaultEvolution({
                    uid: uuidv4(),
                    boostFlags: getBoostFlags(TYPE_BANNER),
                    style: themeStepStyle || getDefaultStepStyle(),
                    steps: [
                      getDefaultStep({
                        name: 'New Step',
                        blocks: [
                          getDefaultBlockFromType(
                            BLOCK_TYPE_TITLE,
                            project.defaultTheme
                          ),
                          getDefaultBlockFromType(
                            BLOCK_TYPE_PRIMARY_CTA,
                            project.defaultTheme
                          ),
                        ],
                      }),
                    ],
                  }),
                ],
              }
            : selectedType === EVOLUTION_TYPE_CHECKLIST
            ? {
                steps: getDefaultChecklistSteps(project),
              }
            : selectedType === EVOLUTION_TYPE_RESOURCE_CENTER
            ? {
                steps: getDefaultResourceCenterStep(user),
                style: getDefaultStepStyle({
                  backgroundColor: '#F3F4FB',
                  shadow: {
                    color: '#00000026',
                    x: 0,
                    y: 12,
                    blur: 38,
                  },
                  position: RESOURCE_CENTER_TRIGGER_POSITION_RIGHT,
                }),
              }
            : {}),
          type: selectedType,
          theme: project.defaultTheme,
          themeId: project.defaultTheme?.uid,
          boostedActiveUrl: isExtension ? currentUrl : url,
          ...(selectedType === EVOLUTION_TYPE_SURVEY
            ? {
                style:
                  themeStepStyle ||
                  getDefaultStepStyle({
                    ...([TYPE_MODAL, TYPE_TOOLTIP].includes(pokeType)
                      ? {overlay: '#0000001a'} // 10% opacity overlay
                      : {}),
                  }),
              }
            : {}),
          isBoostOf: boostOf,
          ...(boostTypeFlags != null
            ? {
                tourSteps: [
                  getDefaultEvolution({
                    boostFlags: boostTypeFlags,
                    steps: [createStepFromPost(boostOf, boostTypeFlags)],
                    style: getDefaultStepStyle(),
                  }),
                ],
                style: getDefaultStepStyle(),
              }
            : {}),
          boostedDotStyle: hotspotStyle?.style || defaultHotspotStyle,
          spaceId,
        });
        if (
          project.defaultTheme != null &&
          selectedTemplate == null &&
          selectedType !== EVOLUTION_TYPE_RESOURCE_CENTER
        ) {
          newEvolution = applyThemeToEvolution(
            newEvolution,
            project.defaultTheme
          );
        }
        createdEvolution = await evolutionService.createEvolution(newEvolution);
        amplitude.getInstance().logEvent(EVENT_CREATE_EXPERIENCE, {
          template: selectedTemplate?.title || 'From scratch',
          type: selectedType,
        });
        amplitude.getInstance().logEvent(EVENT_CREATE_PUSH, {
          template: selectedTemplate != null ? selectedTemplate.id : null,
          type:
            selectedTemplate != null
              ? selectedTemplate.experienceType
              : pokeType,
          from: isExtension === true ? 'Extension' : 'Pokes',
        });
        createdEvolutionRef.current = createdEvolution;
      } else {
        await evolutionService.updateEvolutionSettings(pokeToEdit.uid, {
          ...evolution,
          boostedActiveUrl: url,
        });
      }
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(`Failed saving evolution: ${code}`);
      toastDanger([title, message], {actions});
      setIsLoading(false);
      return;
    }
    if (isExtension === true) {
      onCreatedPoke(createdEvolution);
    } else {
      if (
        hasBuilderAccess === true &&
        (editInApp === true ||
          pokeToEdit != null ||
          mode === MODE_TOUR_GENERATION)
      ) {
        if (isExtensionInstalled === true) {
          sendUrlToExtension(url);
        }
        const childTab = refParent.current?.openNewTab({
          url,
          windowName: 'AcrossTab',
          ...(isSnippetInstalled === true
            ? {
                // windowFeatures:
                //   'toolbar=no,location=yes,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,copyhistory=no',
              }
            : {}),
        });
        childTabId.current = childTab.id;

        setMode(MODE_PENDING);
      } else {
        history.push(
          ROUTE_POKE_BUILDER_FROM_TYPE(
            createdEvolution.uid,
            createdEvolution.type
          )
        );
      }
    }

    setIsLoading(false);
  };

  const handleInputOnPressEnter = () => {
    if (url.length > 0) {
      handleCreatePoke();
    }
  };

  const handleStopInAppEditing = async () => {
    const isInEditor = [ROUTE_POKE_BUILDER_FROM_TYPE()].includes(match.path);
    setIsLoading(true);
    if (refetchEvolution != null && isInEditor === true) {
      await refetchEvolution();
    }
    refParent.current?.closeAllTabs();
    setUrl('');
    setCreatedPoke(null);
    setIsLoading(false);
    onRequestClose();
    if (isInEditor !== true) {
      history.push(
        ROUTE_POKE_BUILDER_FROM_TYPE(
          createdEvolutionRef.current?.uid,
          createdEvolutionRef.current?.type
        )
      );
    }
  };

  const handleDeleteUrl = async (urlId) => {
    try {
      await buildUrlService.deleteBuildUrl(urlId);
      toastSuccess('Url deleted', {toastId: 'url-detected'});
      refreshBuildUrls();
    } catch (err) {
      const {title, message, actions} = errorHelpers.parseError(err);

      toastDanger([title, message], {actions});
    }
  };

  const getTitle = () => {
    const hasBackButton =
      [MODE_PREVIEW, MODE_INSTALLATION, MODE_TOUR_GENERATION].includes(mode) ||
      (mode === MODE_FORM &&
        [EVOLUTION_TYPE_HINT, EVOLUTION_TYPE_CHECKLIST].includes(forcedType) !==
          true);

    let text = `New ${displayType}`;

    if (mode === MODE_EDIT) {
      text = `Edit a ${displayType} in-app`;
      if (previewOnly === true) {
        text = `Preview your ${displayType}`;
      }
    }
    if (mode === MODE_INSTALLATION) {
      text = 'How do you want to start?';

      if (modeInstall === MODE_INSTALL) {
        text = 'Install Jimo snippet';
      } else if (modeInstall === MODE_TRY) {
        text = 'Install Jimo Chrome extension';
      }
    }
    if (mode === MODE_PENDING) {
      if (previewOnly === true) {
        text = 'In-app preview in progress...';
      } else {
        text = 'In-app editing in progress...';
      }
    }
    if (mode === MODE_PICK_TEMPLATE) {
      text = null;
    }
    if (mode === MODE_FORM && selectedTemplate != null) {
      text = `Create ${selectedTemplate.experienceType.toLowerCase()} from template`;
    }
    if (mode === MODE_TOUR_GENERATION) {
      text = 'Generate a tour with AI';
    }
    if (boostOf != null) {
      text = (
        <>
          <i className="icon-send-o" />
          Post boosting
        </>
      );
    }

    return (
      <>
        {hasBackButton && (
          <Button
            className="back-btn"
            iconOnly
            iconLeft="icon-chevron-left"
            onClick={() => {
              if (mode === MODE_INSTALLATION) {
                if (
                  modeInstall == null ||
                  isChromeExtensionCompatible() !== true
                ) {
                  return setMode(MODE_PICK_TEMPLATE);
                } else {
                  return setModeInstall(null);
                }
              }
              if (mode === MODE_PREVIEW) {
                setSelectedTemplate(null);
                return setMode(MODE_PICK_TEMPLATE);
              }
              if (mode === MODE_FORM || mode === MODE_TOUR_GENERATION) {
                if (selectedTemplate != null) {
                  return setMode(MODE_PREVIEW);
                } else {
                  return setMode(MODE_PICK_TEMPLATE);
                }
              }
              setPokeType(null);
            }}
          />
        )}
        <div className="text">{text}</div>
      </>
    );
  };

  const filteredUrl = buildUrls?.filter((bu) => bu.url.includes(url || ''));

  if (createdPoke != null) {
    return (
      <Modal
        className="modal-create-poke"
        title={`New ${displayType} successfully created`}
        isOpen={isOpen}
        footer={<Button onClick={closeModal}>Close</Button>}
        {...rest}
        onRequestClose={closeModal}>
        <div className="fade-in-right">
          Your new {createdPoke.type} {createdPoke.name} has been successfully
          created !
        </div>
      </Modal>
    );
  }

  let content, footer;

  if (mode === MODE_PICK_BOOST) {
    content = (
      <PickBoostType
        onBoostTypeClick={(typeFlags) => {
          if (typeFlags === F_BOOST_SLOT_TOP_BAR) {
            setForcedType(EVOLUTION_TYPE_BANNER);
          } else {
            setForcedType(EVOLUTION_TYPE_TOUR);
          }
          setBoostTypeFlags(typeFlags);
          setMode(MODE_FORM);
        }}
      />
    );
  }

  if (mode === MODE_PICK_TEMPLATE) {
    content = (
      <TemplatesModal
        type={type}
        onClickTemplate={(template) => {
          setSelectedTemplate(template);
          setMode(MODE_PREVIEW);
        }}
        onClickStartFromScratch={(type) => {
          setForcedType(type);
          setMode(MODE_FORM);
        }}
        onClickGenerateTourWithAI={() => {
          setForcedType(EVOLUTION_TYPE_TOUR);
          setMode(MODE_TOUR_GENERATION);
        }}
      />
    );
  }
  if (mode === MODE_PREVIEW) {
    content = (
      <TemplateDetails
        template={selectedTemplate}
        onUseTemplateClick={() => {
          if (hasBuilderAccess === true || isExtension === true) {
            setMode(MODE_FORM);
          } else {
            setMode(MODE_INSTALLATION);
          }
        }}
        onBack={() => {
          setSelectedTemplate(null);
          setMode(MODE_PICK_TEMPLATE);
          onBack();
        }}
        useDefaultTheme
      />
    );
  }
  if (mode === MODE_INSTALLATION) {
    content = (
      <div className="modal-content installation">
        <InstallJimo
          mode={modeInstall}
          setMode={setModeInstall}
          onExtensionInstalledContinue={() => setMode(MODE_FORM)}
          onSkip={() => setMode(MODE_FORM)}
        />
      </div>
    );
  }

  if (mode === MODE_FORM) {
    content = (
      <form>
        <div className="modal-content">
          <div className="inputs-wrapper">
            <Input
              legend={`${
                displayType != null
                  ? displayType?.charAt(0).toUpperCase() + displayType?.slice(1)
                  : 'Experience'
              } name`}
              className="poke-title"
              placeholder={
                boostOf != null
                  ? `${boostOf.title} - Boosted`
                  : `New ${displayType} name`
              }
              name="title"
              value={title}
              onChange={({target}) => setTitle(target.value)}
              onPressEnter={() => {
                if (
                  !title ||
                  (hasBuilderAccess &&
                    isExtension !== true &&
                    editInApp !== false &&
                    !url)
                ) {
                  return;
                }
                handleCreatePoke();
              }}
            />

            {isExtension !== true &&
              [
                EVOLUTION_TYPE_CHECKLIST,
                EVOLUTION_TYPE_RESOURCE_CENTER,
              ].includes(forcedType) !== true && (
                <Tooltip
                  offsetY={6}
                  className="in-app-toggle-tooltip"
                  disabled={hasBuilderAccess === true}
                  trigger={
                    <div className="in-app-toggle-wrapper">
                      <div className="content">
                        <div className="subtitle-4 n-800">
                          Edit Experience in-App
                        </div>
                        <div className="body-3 n-700">
                          Enable to input a desired URL for direct in-app
                          experience editing
                        </div>
                      </div>
                      <Toggle
                        checked={hasBuilderAccess !== true ? false : editInApp}
                        disabled={hasBuilderAccess !== true}
                        onChange={() => setEditInApp(!editInApp)}
                      />{' '}
                    </div>
                  }>
                  You need to have Jimo snippet installed on your website or
                  Jimo Chrome extension to be able to edit your {displayType} in
                  your app.
                </Tooltip>
              )}
            {hasBuilderAccess === true &&
              isExtension !== true &&
              editInApp !== false && (
                <>
                  <div className="poke-url-wrapper">
                    <div className="input-group-wrapper">
                      <Input
                        legend={`Build this ${displayType} on`}
                        autoComplete="off"
                        className={classnames('poke-url', {
                          'is-invalid': isInvalidUrl,
                        })}
                        placeholder="https://"
                        name="targetUrl"
                        type="url"
                        value={url}
                        iconRight={
                          isInvalidUrl
                            ? 'icon-exclamation-circle'
                            : 'icon-expand'
                        }
                        onChange={({target}) => setUrl(target.value)}
                        onFocus={() => setUrlInputFocused(true)}
                      />
                      {isInvalidUrl && (
                        <div className="body-4 r-400">
                          Please enter a valid URL, including the protocol
                          (e.g., “https://”).
                        </div>
                      )}
                    </div>
                    {filteredUrl?.length > 0 && (
                      <Dropdown
                        className={classnames(
                          'dropdown-build-url-suggestions',
                          {
                            'not-focused': urlInputFocused !== true,
                          }
                        )}
                        open={urlInputFocused === true}
                        onClose={() => setUrlInputFocused(false)}
                        offsetX={5}
                        offsetY={-8}>
                        {filteredUrl
                          .sort(
                            (a, b) =>
                              new Date(b.lastUsed).getTime() -
                              new Date(a.lastUsed).getTime()
                          )
                          ?.map((bu) => {
                            return (
                              <div
                                className="url-suggestion"
                                onClick={() => {
                                  setUrl(bu.url);
                                  setUrlInputFocused(false);
                                }}>
                                {bu.url}
                                {/* For some reasons, using a Button here make the input buggy */}
                                <div
                                  className="btn-delete-url"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    handleDeleteUrl(bu.uid);
                                  }}>
                                  <i className="icon-trash"></i>
                                </div>
                              </div>
                            );
                          })}
                      </Dropdown>
                    )}
                  </div>
                  {isExtensionInstalled === true &&
                    isSnippetInstalled === false && (
                      <Alert className="extension-alert" info withIcon>
                        As you are using Jimo Chrome extension and haven't fully
                        installed Jimo yet, you will need to launch the
                        extension in the newly created tab to be able to edit
                        your {displayType}.
                      </Alert>
                    )}
                </>
              )}
          </div>
        </div>
      </form>
    );

    footer = (
      <>
        <Button
          muted
          className="back-btn"
          type="button"
          onClick={() => {
            setMode(MODE_PICK_TEMPLATE);
            setSelectedTemplate(null);
            setUrl('');
            setTitle('');
            onBack();
          }}>
          Back
        </Button>
        <Button
          primary
          disabled={
            !title ||
            (hasBuilderAccess &&
              isExtension !== true &&
              editInApp !== false &&
              !url)
          }
          loading={isLoading}
          onClick={handleCreatePoke}>
          {previewOnly === true ? 'Launch preview' : 'Enter builder'}
        </Button>
      </>
    );
  }
  if (mode === MODE_TOUR_GENERATION) {
    content = (
      <form>
        <div className="modal-content">
          <div className="inputs-wrapper">
            <Input
              legend={`${
                displayType != null
                  ? displayType?.charAt(0).toUpperCase() + displayType?.slice(1)
                  : 'Experience'
              } name`}
              className="poke-title"
              placeholder={
                boostOf != null
                  ? `${boostOf.title} - Boosted`
                  : `New ${displayType} name`
              }
              name="title"
              value={title}
              onChange={({target}) => setTitle(target.value)}
              onPressEnter={() => {
                if (
                  !title ||
                  (hasBuilderAccess && isExtension !== true && !url)
                ) {
                  return;
                }
                handleCreatePoke();
              }}
            />
            {hasBuilderAccess === true && isExtension !== true && (
              <>
                <div className="poke-url-wrapper">
                  <div className="input-group-wrapper">
                    <Input
                      legend={`Build this ${displayType} on`}
                      autoComplete="off"
                      className={classnames('poke-url', {
                        'is-invalid': isInvalidUrl,
                      })}
                      placeholder="https://"
                      name="targetUrl"
                      type="url"
                      value={url}
                      iconRight={
                        isInvalidUrl ? 'icon-exclamation-circle' : 'icon-expand'
                      }
                      onChange={({target}) => setUrl(target.value)}
                      onFocus={() => setUrlInputFocused(true)}
                    />
                    {isInvalidUrl && (
                      <div className="body-4 r-400">
                        Please enter a valid URL, including the protocol (e.g.,
                        “https://”).
                      </div>
                    )}
                  </div>
                  {filteredUrl?.length > 0 && (
                    <Dropdown
                      className={classnames('dropdown-build-url-suggestions', {
                        'not-focused': urlInputFocused !== true,
                      })}
                      open={urlInputFocused === true}
                      onClose={() => setUrlInputFocused(false)}
                      offsetX={5}
                      offsetY={-8}>
                      {filteredUrl
                        .sort(
                          (a, b) =>
                            new Date(b.lastUsed).getTime() -
                            new Date(a.lastUsed).getTime()
                        )
                        ?.map((bu) => {
                          return (
                            <div
                              className="url-suggestion"
                              onClick={() => {
                                setUrl(bu.url);
                                setUrlInputFocused(false);
                              }}>
                              {bu.url}
                              {/* For some reasons, using a Button here make the input buggy */}
                              <div
                                className="btn-delete-url"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  handleDeleteUrl(bu.uid);
                                }}>
                                <i className="icon-trash"></i>
                              </div>
                            </div>
                          );
                        })}
                    </Dropdown>
                  )}
                </div>
                {isExtensionInstalled === true &&
                  isSnippetInstalled === false && (
                    <Alert className="extension-alert" info withIcon>
                      As you are using Jimo Chrome extension and haven't fully
                      installed Jimo yet, you will need to launch the extension
                      in the newly created tab to be able to edit your{' '}
                      {displayType}.
                    </Alert>
                  )}
              </>
            )}
          </div>
        </div>
      </form>
    );

    footer = (
      <>
        <Button
          muted
          className="back-btn"
          type="button"
          onClick={() => {
            setMode(MODE_PICK_TEMPLATE);
            setSelectedTemplate(null);
            setUrl('');
            setTitle('');
            onBack();
          }}>
          Back
        </Button>
        <Button
          primary
          disabled={
            !title || (hasBuilderAccess && isExtension !== true && !url)
          }
          loading={isLoading}
          onClick={handleCreatePoke}>
          {previewOnly === true ? 'Launch preview' : 'Enter builder'}
        </Button>
      </>
    );
  }
  if (mode === MODE_EDIT) {
    content = (
      <form>
        <div className="modal-content">
          <div className="inputs-wrapper">
            <div className="poke-url-wrapper">
              <div className="input-group-wrapper">
                <Input
                  legend={previewOnly === true ? 'Preview on' : 'Edit on'}
                  autoComplete="off"
                  className={classnames('poke-url', {
                    'is-invalid': isInvalidUrl,
                  })}
                  placeholder="https://"
                  name="targetUrl"
                  value={url}
                  iconRight={
                    isInvalidUrl ? 'icon-exclamation-circle' : 'icon-expand'
                  }
                  onChange={({target}) => setUrl(target.value)}
                  onFocus={() => setUrlInputFocused(true)}
                  type="url"
                  onPressEnter={handleInputOnPressEnter}
                />
                {isInvalidUrl && (
                  <div className="body-4 r-400">
                    Please enter a valid URL, including the protocol (e.g.,
                    “https://”).
                  </div>
                )}
              </div>
              {filteredUrl?.length > 0 && (
                <Dropdown
                  className={classnames('dropdown-build-url-suggestions', {
                    'not-focused': urlInputFocused !== true,
                  })}
                  open={urlInputFocused === true}
                  onClose={() => setUrlInputFocused(false)}
                  offsetX={5}
                  offsetY={-8}>
                  {filteredUrl?.map((bu) => {
                    return (
                      <div
                        className="url-suggestion"
                        onClick={() => {
                          setUrl(bu.url);
                          setUrlInputFocused(false);
                        }}>
                        {bu.url}
                        {/* For some reasons, using a Button here make the input buggy */}
                        <div
                          className="btn-delete-url"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleDeleteUrl(bu.uid);
                          }}>
                          <i className="icon-trash"></i>
                        </div>
                      </div>
                    );
                  })}
                </Dropdown>
              )}
            </div>
            {url !== pokeToEdit.boostedActiveUrl &&
              pokeToEdit.boostedActiveOperator ===
                PAGE_ACTIVE_OPERATOR_SINGLE_URL && (
                <Alert className="extension-alert" warning>
                  By changing the url you edit the {displayType} on, this will
                  also update the url we should display the {displayType} on to
                  the specified url.
                </Alert>
              )}
            {isExtensionInstalled === true && isSnippetInstalled === false && (
              <Alert className="extension-alert" info withIcon>
                As you are using Jimo Chrome extension and haven't fully
                installed Jimo yet, you will need to launch the extension in the
                newly created tab to be able to edit your {displayType}.
              </Alert>
            )}
            <Alert
              className="attributes-alert"
              warning
              icon={'isax isax-info-circle5'}
              title={'Attributes will display fallback values on Preview'}>
              In previews, attributes will show fallback values or appear as
              empty text if none are set. Live experiences will display actual
              attribute values.
            </Alert>
          </div>
        </div>
      </form>
    );

    footer = (
      <>
        <Button
          type="button"
          muted
          className="back-btn"
          onClick={() => {
            closeModal();
          }}>
          Cancel
        </Button>
        <Button
          primary
          disabled={!url}
          loading={isLoading}
          onClick={handleCreatePoke}>
          {previewOnly === true ? 'Launch preview' : 'Enter builder'}
        </Button>
      </>
    );
  }
  if (mode === MODE_PENDING) {
    content = (
      <>
        <div className="modal-content">
          <div className="pending-wrapper">
            <div className="pending-subtitle">
              You can close this window if you are done{' '}
              {previewOnly === true ? 'previewing' : 'editing'} your{' '}
              {displayType} in-app!
            </div>
          </div>
        </div>
      </>
    );

    footer = (
      <>
        <Button
          muted
          className="close-btn"
          onClick={() => {
            handleStopInAppEditing();
          }}
          loading={isLoading}>
          Close
        </Button>
      </>
    );
  }

  return (
    <Modal
      className={classnames('modal-create-poke', {
        'mode-pick-template': mode === MODE_PICK_TEMPLATE,
        'mode-preview': mode === MODE_PREVIEW,
      })}
      title={getTitle()}
      isOpen={isOpen}
      footer={footer}
      {...rest}
      onRequestClose={closeModal}
      animationOnOpen="scale-in-center">
      <div
        className={classnames('content-wrapper', {
          scrollable: [MODE_PICK_TEMPLATE].includes(mode),
        })}>
        {content}
      </div>
    </Modal>
  );
};

ModalCreatePoke.propTypes = propTypes;

export default React.memo(ModalCreatePoke);
