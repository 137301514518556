import classNames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import DefaultLoader from 'components/Loader';
import ModalLaunchBuilder from 'components/ModalLaunchBuilder';
import SelectGroup from 'components/Select';
import {toastSuccess} from 'components/Toaster';
import {GlobalContext} from 'contextes/Global';
import {errorHelpers} from 'helpers';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import {sendSetParentTab} from 'helpers/utils';
import useAcrossTabs from 'hooks/UseAcrossTabs';
import {useInAppBuilder} from 'hooks/useInAppBuilder';
import useScrollDetector from 'hooks/UseScrollDetector';
import React, {createContext, useContext, useRef, useState} from 'react';
import {useQuery} from 'react-query';
import {useRouteMatch} from 'react-router-dom';
import {components} from 'react-select';
import {InAppBuilderContext} from 'scenes/Pushes/context';
import {evolutionService} from 'services';
import {
  EVOLUTION_TYPE_BANNER,
  EVOLUTION_TYPE_CHECKLIST,
  EVOLUTION_TYPE_HINT,
  EVOLUTION_TYPE_RESOURCE_CENTER,
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
  F_OPTION_IGNORE_RATE_LIMITING,
  TRIGGER_TYPE_NONE,
  TRIGGER_TYPE_URL,
} from 'services/evolution';
import {Swaler} from 'swaler';
import {v4 as uuidv4} from 'uuid';
import './_styles.scss';
import PokeAudienceHeader from './components/AudienceHeader';
import ModalCopySettingsFrom from './components/ModalCopySettingsFrom';
import Goal from './sections/Goal';
import Summary from './sections/Summary';
import Trigger from './sections/Trigger';
import When from './sections/When';
import Where from './sections/Where';
import Who from './sections/Who';
import {replaceUidsInObject} from './utils';

export const SECTION_TRIGGER = 'TRIGGER';
export const SECTION_WHERE = 'WHERE';
export const SECTION_WHO = 'WHO';
export const SECTION_WHEN = 'WHEN';
export const SECTION_GOAL = 'GOAL';
export const SECTION_SUMMARY = 'SUMMARY';

export const AudienceContext = createContext({
  evolution: null,
  setEvolution: () => {},
  refetchEvolution: () => {},
  selectedSection: SECTION_TRIGGER,
  setSelectedSection: () => {},
  jimersCount: null,
  setJimersCount: () => {},
  save: () => {},
});

const logger = new Swaler('PokeAudience');

const sections = [
  {section: SECTION_TRIGGER, component: Trigger},
  {section: SECTION_WHERE, component: Where},
  {section: SECTION_WHO, component: Who},
  {section: SECTION_WHEN, component: When},
  {section: SECTION_GOAL, component: Goal},
];

const Option = (props) => {
  const {data} = props;

  return (
    <components.Option {...props}>
      <div className="option-wrapper">
        <div className="icon-wrapper">{data.icon}</div>
        <div className="title body-3 n-800">{data.label}</div>
      </div>
    </components.Option>
  );
};

const ValueContainer = ({children, ...props}) => {
  return (
    <components.ValueContainer className="value-container body-3" {...props}>
      <div className="img-wrapper">
        <i className="isax isax-search-normal-1 n-500" />
      </div>
      <div className="value-container-content">
        <components.Placeholder
          className="placeholder n-500"
          {...props}
          isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </components.Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== components.Placeholder ? child : null
        )}
      </div>
    </components.ValueContainer>
  );
};

const PokeAudience = () => {
  let [evolution, setEvolution] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [jimersCount, setJimersCount] = useState(null);
  const [showExperiencesDropdown, setShowExperiencesDropdown] = useState(false);
  const [copySettingsFrom, setCopySettingsFrom] = useState(null);
  const [disableAnimation, setDisableAnimation] = useState(false);

  const contentRef = useRef();

  const isScrolled = useScrollDetector(contentRef);

  const match = useRouteMatch();
  const evolutionId = match?.params?.evolutionId;

  const {data: experiences = [], isLoading: isLoadingExperiences} = useQuery({
    queryKey: 'experiences',
    queryFn: async () => {
      try {
        const experiences = await evolutionService.getEvolutions({
          relations: [],
          onlyPokes: true,
          removeDrafts: true,
        });

        return experiences;
      } catch (err) {
        const {code} = errorHelpers.parseError(err);

        logger.error('Fetch experiences failed with error ', code);
      }
    },
  });

  const {isLoading, refetch} = useQuery({
    queryKey: ['evolution', 'poke-audience', evolutionId],
    queryFn: async () => {
      const evolution = await evolutionService.getEvolutionById(evolutionId, {
        relations: [
          'texts',
          'tags',
          'segments',
          'steps',
          'boostedPaths',
          'event',
          'tourSteps',
          'theme',
          'section',
          'tracker',
          'environments',
        ],
      });

      return setEvolution(evolution);
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });

  const save = async () => {
    try {
      await evolutionService.updateEvolutionSettings(evolution.uid, {
        ...evolution,
      });
    } catch (err) {
      logger.error('Publishing failed with error ', err);
    }
  };

  const isHint = evolution?.type === EVOLUTION_TYPE_HINT;
  const isSurvey = evolution?.type === EVOLUTION_TYPE_SURVEY;
  const isChecklist = evolution?.type === EVOLUTION_TYPE_CHECKLIST;
  const isResourceCenter = evolution?.type === EVOLUTION_TYPE_RESOURCE_CENTER;

  let availableSections = sections;
  let copyableSections = sections.filter(
    (s) => [SECTION_GOAL].includes(s.section) !== true
  );

  if (
    evolution?.triggerType === TRIGGER_TYPE_URL ||
    evolution?.triggerType === TRIGGER_TYPE_NONE
  ) {
    availableSections = sections.filter((s) =>
      [SECTION_TRIGGER, SECTION_GOAL].includes(s.section)
    );
  }
  if (isHint) {
    availableSections = sections.filter((s) =>
      [SECTION_WHERE, SECTION_WHO, SECTION_WHEN].includes(s.section)
    );
    copyableSections = sections.filter((s) =>
      [SECTION_WHERE, SECTION_WHO, SECTION_WHEN].includes(s.section)
    );
  }

  if (isSurvey) {
    availableSections = availableSections.filter((s) =>
      [SECTION_TRIGGER, SECTION_WHERE, SECTION_WHO, SECTION_WHEN].includes(
        s.section
      )
    );
    copyableSections = copyableSections.filter((s) =>
      [SECTION_TRIGGER, SECTION_WHERE, SECTION_WHO, SECTION_WHEN].includes(
        s.section
      )
    );
  }

  if (isChecklist) {
    availableSections = availableSections.filter((s) =>
      [SECTION_TRIGGER, SECTION_WHERE, SECTION_WHO].includes(s.section)
    );
    copyableSections = copyableSections.filter((s) =>
      [SECTION_TRIGGER, SECTION_WHERE, SECTION_WHO].includes(s.section)
    );
  }

  if (isResourceCenter) {
    availableSections = availableSections.filter((s) =>
      [SECTION_WHERE, SECTION_WHO, SECTION_WHEN].includes(s.section)
    );
    copyableSections = copyableSections.filter((s) =>
      [SECTION_WHERE, SECTION_WHO, SECTION_WHEN].includes(s.section)
    );
  }

  const handleApplySettings = async (copiedEvolution) => {
    let cleanOnTheFlyEvent = null;
    let cleanOnTheFlySegment = null;
    let optionsFlags = evolution.optionsFlags;

    const sections = copyableSections.map((s) => s.section);

    if (copiedEvolution.onTheFlyEvent != null) {
      const conditionsIdMapping = {};
      copiedEvolution.onTheFlyEvent.conditions.forEach((c) => {
        conditionsIdMapping[c.uid] = uuidv4();
      });

      cleanOnTheFlyEvent = {
        conditions: copiedEvolution.onTheFlyEvent.conditions.map((c) => ({
          ...c,
          uid: conditionsIdMapping[c.uid],
        })),
        logic: replaceUidsInObject(
          copiedEvolution.onTheFlyEvent.logic,
          conditionsIdMapping
        ),
      };
    }

    if (copiedEvolution.onTheFlySegment != null) {
      const attributesIdMapping = {};
      copiedEvolution.onTheFlySegment.attributes.forEach((a) => {
        attributesIdMapping[a.uid] = uuidv4();
      });
      cleanOnTheFlySegment = {
        attributes: copiedEvolution.onTheFlySegment.attributes.map((a) => ({
          ...a,
          uid: attributesIdMapping[a.uid],
        })),
        logic: replaceUidsInObject(
          copiedEvolution.onTheFlySegment.logic,
          attributesIdMapping
        ),
      };
    }

    if (sections.includes(SECTION_WHEN) === true) {
      if (
        hasFlag(F_OPTION_IGNORE_RATE_LIMITING, copiedEvolution.optionsFlags)
      ) {
        optionsFlags = addFlag(
          F_OPTION_IGNORE_RATE_LIMITING,
          evolution.optionsFlags
        );
      } else {
        optionsFlags = removeFlag(
          F_OPTION_IGNORE_RATE_LIMITING,
          evolution.optionsFlags
        );
      }
    }

    const updatedEvolution = {
      ...evolution,
      ...(sections.includes(SECTION_TRIGGER)
        ? {
            triggerType: copiedEvolution.triggerType,
            boostedDelay: copiedEvolution.boostedDelay,
            event: copiedEvolution.event,
            onTheFlyEvent: cleanOnTheFlyEvent,
          }
        : {}),
      ...(sections.includes(SECTION_WHERE)
        ? {
            deviceFlags: copiedEvolution.deviceFlags,
            boostedActiveOperator: copiedEvolution.boostedActiveOperator,
            boostedActiveUrl: copiedEvolution.boostedActiveUrl,
            boostedPaths: copiedEvolution.boostedPaths.map((p) => ({
              ...p,
              uid: uuidv4(),
            })),
            boostedPathOperator: copiedEvolution.boostedPathOperator,
            boostedDomainFilter: copiedEvolution.boostedDomainFilter,
          }
        : {}),
      ...(sections.includes(SECTION_WHO)
        ? {
            segments: copiedEvolution.segments,
            onTheFlySegment: cleanOnTheFlySegment,
          }
        : {}),
      ...(sections.includes(SECTION_WHEN)
        ? {
            lastStepChangeAt: copiedEvolution.lastStepChangeAt,
            expiresAt: copiedEvolution.expiresAt,
            recurrencyValue: copiedEvolution.recurrencyValue,
            recurrencyType: copiedEvolution.recurrencyType,
            optionsFlags: optionsFlags,
            priority: copiedEvolution.priority,
          }
        : {}),
    };

    setEvolution(updatedEvolution);
    setCopySettingsFrom(null);
    toastSuccess('Settings applied successfully');
  };

  const experiencesOptions = experiences
    ?.map((e) => {
      let icon;

      if (e.type === EVOLUTION_TYPE_TOUR) {
        icon = <i className="isax isax-routing-25" />;
      } else if (e.type === EVOLUTION_TYPE_SURVEY) {
        icon = <i className="isax isax-note-215" />;
      } else if (e.type === EVOLUTION_TYPE_BANNER) {
        icon = <i className="icon-banner-active" />;
      } else if (e.type === EVOLUTION_TYPE_HINT) {
        icon = <i className="isax isax-notification-15" />;
      }

      return {
        label: e.title,
        value: e.uid,
        evolution: e,
        icon: icon,
      };
    })
    .filter((e) => e.value !== evolutionId);

  const groupedOptions = [
    {
      label: 'Tours & Modals',
      options: experiencesOptions.filter(
        (e) => e.evolution.type === EVOLUTION_TYPE_TOUR
      ),
    },
    {
      label: 'Surveys',
      options: experiencesOptions.filter(
        (e) => e.evolution.type === EVOLUTION_TYPE_SURVEY
      ),
    },
    {
      label: 'Banners',
      options: experiencesOptions.filter(
        (e) => e.evolution.type === EVOLUTION_TYPE_BANNER
      ),
    },
    {
      label: 'Hints',
      options: experiencesOptions.filter(
        (e) => e.evolution.type === EVOLUTION_TYPE_HINT
      ),
    },
  ];

  const {parentTabRef, childTabIdRef, handshakeDataRef} =
    useContext(GlobalContext);

  const {openNewTab} = useAcrossTabs({
    onHandshakeCallback: () => {
      sendSetParentTab();

      parentTabRef.current?.broadCastTo(
        childTabIdRef.current,
        handshakeDataRef.current
      );
    },
  });

  const {
    openInAppBuilderAt,
    isEditingInApp,
    launchBuilderModalData,
    closeLaunchBuilderModal,
    goToEditInApp,
    stopInAppEditing,
  } = useInAppBuilder({openNewTab});

  return (
    <AudienceContext.Provider
      value={{
        evolution,
        setEvolution,
        refetchEvolution: refetch,
        selectedSection,
        setSelectedSection,
        jimersCount,
        setJimersCount,
        save,
        isBoosted: evolution?.isBoostOf != null,
        sections: availableSections,
        disableAnimation,
        setDisableAnimation,
      }}>
      <InAppBuilderContext.Provider
        value={{
          openInAppBuilderAt,
          isEditingInApp,
          launchBuilderModalData,
          closeLaunchBuilderModal,
          goToEditInApp,
          stopInAppEditing,
        }}>
        <div
          className={classNames('poke-audience-wrapper', {
            'is-empty': evolution == null,
          })}>
          <>
            {evolution != null && <PokeAudienceHeader scrolled={isScrolled} />}
            <div className="poke-audience" ref={contentRef}>
              {isLoading === true ? (
                <div className="loader-wrapper">
                  <DefaultLoader />
                </div>
              ) : evolution == null ? (
                <div>This evolution does not exist</div>
              ) : (
                <>
                  <Summary />
                  <Divider />
                  <div className="poke-audience-customize subtitle-2 n-800">
                    Customize
                    {showExperiencesDropdown ? (
                      <SelectGroup
                        autoFocus
                        defaultMenuIsOpen
                        menuIsOpen
                        hideDropdown
                        wrapperClassName="poke-audience-apply-settings-dropdown"
                        placeholder="Search experience..."
                        options={groupedOptions}
                        onChange={(option) => {
                          setCopySettingsFrom(option.evolution);
                          setShowExperiencesDropdown(false);
                        }}
                        onBlur={() => setShowExperiencesDropdown(false)}
                        styles={{
                          menu: () => ({
                            position: 'absolute',
                            right: 0,
                            top: 'calc(100% + 8px)',
                            'overflow-x': 'hidden',
                          }),
                          placeholder: (base, state) => ({
                            ...base,
                            position: 'absolute',
                            display:
                              state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused
                                ? 'none'
                                : 'block',
                          }),
                        }}
                        components={{
                          Option,
                          ValueContainer,
                        }}
                        isLoading={isLoadingExperiences}
                      />
                    ) : (
                      <Button
                        className="poke-audience-apply-settings-btn"
                        iconLeft="isax isax-element-plus"
                        onClick={() => setShowExperiencesDropdown(true)}>
                        Load settings
                      </Button>
                    )}
                  </div>
                  {availableSections.map((section) => (
                    <section.component key={section.section} />
                  ))}
                </>
              )}
            </div>
          </>

          {launchBuilderModalData != null && (
            <ModalLaunchBuilder
              isOpen={launchBuilderModalData != null}
              onRequestClose={() => {
                closeLaunchBuilderModal();
              }}
              onOpenUrl={(url) => openInAppBuilderAt(url)}
              data={launchBuilderModalData}
            />
          )}

          {copySettingsFrom != null && (
            <ModalCopySettingsFrom
              isOpen={copySettingsFrom != null}
              onRequestClose={() => setCopySettingsFrom(null)}
              onApplySettings={handleApplySettings}
              evolution={copySettingsFrom}
              availableSections={copyableSections}
            />
          )}
        </div>
      </InAppBuilderContext.Provider>
    </AudienceContext.Provider>
  );
};

export default PokeAudience;
