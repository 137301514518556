import Button from 'components/Button';
import {Modal} from 'components/Modal';
import React from 'react';
import './_Styles.scss';

const TourGenerationPicker = ({
  isOpen,
  onRequestClose,
  onStartClick = () => {},
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="tour-generation-picker">
      <div className="tour-generation-picker-header">
        <div className="tour-generation-picker-header-title-wrapper">
          <div className="tour-generation-picker-header-title title-4 white">
            Jimo AI Builder
          </div>
          <div className="tour-generation-picker-header-subtitle body-3 n-500">
            What's your goal today?
          </div>
        </div>
        <div className="tour-generation-picker-header-close">
          <i className="icon-close n-500" />
        </div>
      </div>
      <div className="tour-generation-picker-content">
        <Button onClick={onStartClick}>Introduce a feature</Button>
      </div>
    </Modal>
  );
};

export default TourGenerationPicker;
