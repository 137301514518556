import {Modal} from 'components/Modal';
import React from 'react';
import './_Styles.scss';

const TourGeneratingModal = ({isOpen = false, onRequestClose = () => {}}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="tour-generating-modal">
      <div className="tour-generating-modal-animation">
        <i className="icon-jimo" />
      </div>
      <div className="tour-generating-modal-content">
        <div className="tour-generating-modal-content-title body-3 white">
          The tour is being generated by Jimo AI
        </div>
        <div className="tour-generating-modal-content-subtitle body-3 b-200">
          This may take up to 30 seconds...
        </div>
      </div>
    </Modal>
  );
};

export default TourGeneratingModal;
