import {miscActions} from 'actions';
import {BuilderContext} from 'contextes/builder';
import {sendSetChildTab} from 'helpers/utils';
import useUndercityBuilderMessenger from 'managers/useDalaranMessenger';
import {useContext, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import * as openaiService from 'services/openai';
import './_Styles.scss';
import TourGeneratingModal from './components/TourGeneratingModal';
import TourGenerationPicker from './components/TourGenerationPicker';
import {generateEvolutionFromSteps} from './utils';

const TourGenerationBuilder = ({onEditTour = () => {}}) => {
  const dispatch = useDispatch();

  const {evolution, setEvolution} = useContext(BuilderContext);

  const [isPickerOpen, setIsPickerOpen] = useState(true);
  const [isGeneratingTour, setIsGeneratingTour] = useState(false);

  const setHideMobileOverlay = (isHidden) =>
    dispatch(miscActions.setHideMobileOverlay(isHidden));

  const handleGenerateTour = async (data) => {
    setIsGeneratingTour(true);
    const res = await openaiService.generateEvolution({
      elements: data.elements,
      pages: data.pages,
    });

    const generatedEvolution = generateEvolutionFromSteps(
      res.tourSteps,
      data.elements
    );

    setEvolution({
      ...evolution,
      tourSteps: generatedEvolution.tourSteps,
    });
    messenger.sendPreviewGeneratedTour({evolution: generatedEvolution});
    setIsGeneratingTour(false);
  };

  const handleEditTour = () => {
    onEditTour();
  };

  const messenger = useUndercityBuilderMessenger({
    onGenerateTour: handleGenerateTour,
    onEditTour: handleEditTour,
  });

  const handleToggleSelectingMode = () => {
    messenger.sendToggleSelectingMode();
    setIsPickerOpen(false);
  };

  useEffect(() => {
    setHideMobileOverlay(true);
  }, []);

  useEffect(() => {
    sendSetChildTab();
  }, []);

  // Hide Crisp
  useEffect(() => {
    if (window.$crisp != null) {
      window.$crisp.push(['do', 'chat:hide']);
    }
  }, []);

  return (
    <>
      {isPickerOpen && (
        <TourGenerationPicker
          isOpen={isPickerOpen}
          onRequestClose={() => setIsPickerOpen(false)}
          onStartClick={handleToggleSelectingMode}
        />
      )}
      {isGeneratingTour && <TourGeneratingModal isOpen={isGeneratingTour} />}
    </>
  );
};

export default TourGenerationBuilder;
