import classnames from 'classnames';
import Loader, {JimoLoader} from 'components/Loader';
import Navbar from 'components/Navbar';
import NavbarSettings from 'components/NavbarSettings';
import {toastDanger} from 'components/Toaster';
import {BuilderContext} from 'contextes/builder';
import {GlobalContext} from 'contextes/Global';
import {errorHelpers} from 'helpers';
import {addFlag} from 'helpers/bitwise';
import {sendSetParentTab} from 'helpers/utils';
import useAcrossTabs from 'hooks/UseAcrossTabs';
import {default as qs, default as querystring} from 'query-string';
import {useContext, useEffect, useState} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {useSelector} from 'react-redux';
import {useHistory, useLocation, withRouter} from 'react-router-dom';
import {PokeBuilderEB} from 'scenes/Error';
import {ProgressionTracker} from 'scenes/Onboarding/components/ProgressionTracker';
import {RegisterTestimonials} from 'scenes/Onboarding/scenes/Register/testimonials';
import {HOTSPOT_SHAPE_DEFAULT} from 'scenes/PokeBuilder/components/BlockEditor/blocks/Hotspot';
import {getDefaultBlockFromType} from 'scenes/PokeBuilder/components/BlockManager/utils';
import PokeBuilderHeader from 'scenes/PokeBuilder/components/PokeBuilderHeader';
import {MODE_NAVIGATOR} from 'scenes/PokeBuilder/components/PokeBuilderSidebar';
import {
  getTextToTranslate,
  setTranslatedTextToEvolution,
} from 'scenes/PokeBuilder/components/TranslationManager/utils';
import PostBuilderHeader from 'scenes/PostBuilder/components/PostBuilderHeader';
import {
  TYPE_BANNER,
  TYPE_HINT,
  TYPE_HOTSPOT,
  TYPE_MODAL,
  TYPE_SNIPPET,
  TYPE_TOOLTIP,
} from 'scenes/Pushes/components/ModalCreatePoke/components/TemplatesModal/templates';

import ModalLaunchBuilder from 'components/ModalLaunchBuilder';
import {useInAppBuilder} from 'hooks/useInAppBuilder';
import {isLogicViewCompatible} from 'scenes/PokeBuilder/utils';
import {InAppBuilderContext} from 'scenes/Pushes/context';
import {generalSelector} from 'selectors';
import {evolutionService, openaiService} from 'services';
import {
  defaultCustomization,
  EVOLUTION_CONTEXT_PORTAL,
  EVOLUTION_TYPE_POST,
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
  F_BOOST_SLOT_TOUR,
  F_OPTION_PORTAL_DISPLAY_FEED,
  F_OPTION_SHOW_ON_PORTAL,
} from 'services/evolution';
import {defaultChangelogStyle} from 'services/project';
import {
  BLOCK_TYPE_BODY,
  BLOCK_TYPE_TITLE,
  getDefaultStep,
} from 'services/steps';
import {Swaler} from 'swaler';
import {useFetchOrCreateEvolution} from '../hooks/useFetchOrCreateEvolution';
import './_Styles.scss';
import {
  ROUTE_BANNER_SETTINGS_WITH_ID,
  ROUTE_BANNER_WITH_ID,
  ROUTE_CHANGELOG_BUILDER,
  ROUTE_CHANGELOG_BUILDER_WITH_ID,
  ROUTE_CHECKLIST_SETTINGS_WITH_ID,
  ROUTE_CHECKLIST_WITH_ID,
  ROUTE_HINT_SETTINGS_WITH_ID,
  ROUTE_HINT_WITH_ID,
  ROUTE_LIGHTWEIGHT_POKE_BUILDER,
  ROUTE_LIGHTWEIGHT_POKE_BUILDER_WITH_ID,
  ROUTE_LOGIN,
  ROUTE_ONBOARDING_DETAILS_JOIN,
  ROUTE_ONBOARDING_DETAILS_STEP_1_1,
  ROUTE_ONBOARDING_DETAILS_STEP_1_2,
  ROUTE_ONBOARDING_DETAILS_STEP_2_1,
  ROUTE_ONBOARDING_DETAILS_STEP_2_2,
  ROUTE_ONBOARDING_DETAILS_STEP_2_3,
  ROUTE_ONBOARDING_DETAILS_STEP_3_1,
  ROUTE_ONBOARDING_DETAILS_STEP_ONBOARDING,
  ROUTE_POKE_BUILDER_FROM_TYPE,
  ROUTE_POST_BUILDER_WRITER,
  ROUTE_REGISTER,
  ROUTE_REGISTER_EMAIL_SENT,
  ROUTE_REGISTER_SETUP_PASSWORD,
  ROUTE_RESOURCE_CENTER_SETTINGS_WITH_ID,
  ROUTE_RESOURCE_CENTER_WITH_ID,
  ROUTE_SETTINGS_INTEGRATIONS_WEBHOOK_DETAILS,
  ROUTE_SETTINGS_THEMES_ID,
  ROUTE_SPACE_WITH_ID,
  ROUTE_SURVEY_SETTINGS_WITH_ID,
  ROUTE_SURVEY_WITH_ID,
  ROUTE_TOUR_SETTINGS_WITH_ID,
  ROUTE_TOUR_WITH_ID,
  ROUTE_USERS_IMPORT_CSV,
  ROUTE_USERS_IMPORT_CSV_WITH_ID,
} from './routes.const';

export const BasicLayout = ({component: Component, ...props}) => {
  const {search} = useLocation();
  const qs = querystring.parse(search);
  const route = props.match.path;

  const [playOnboardingArrival, setPlayOnboardingArrival] = useState(true);

  return (
    <div className={classnames('basic-layout')}>
      {qs['et-voila'] != null && (
        <div
          className={classnames('layout-onboarding-arrival', {
            'is-exiting': playOnboardingArrival === false,
          })}>
          <JimoLoader
            size="big"
            lottieOptions={{loop: false}}
            eventListeners={[
              {
                eventName: 'complete',
                callback() {
                  setTimeout(() => {
                    setPlayOnboardingArrival(false);
                    window?.jimo?.push([
                      'do',
                      'boosted:trigger',
                      [{evolutionId: '17e64f27-a089-40c0-ad8b-51f532620ce4'}],
                    ]);
                  }, 1200);
                },
              },
            ]}
          />
        </div>
      )}
      <Navbar
        compact={[
          ROUTE_USERS_IMPORT_CSV,
          ROUTE_USERS_IMPORT_CSV_WITH_ID(),
          ROUTE_TOUR_SETTINGS_WITH_ID(),
          ROUTE_SURVEY_SETTINGS_WITH_ID(),
          ROUTE_BANNER_SETTINGS_WITH_ID(),
          ROUTE_HINT_SETTINGS_WITH_ID(),
          ROUTE_CHECKLIST_SETTINGS_WITH_ID(),
          ROUTE_RESOURCE_CENTER_SETTINGS_WITH_ID(),
          ROUTE_TOUR_WITH_ID(),
          ROUTE_SURVEY_WITH_ID(),
          ROUTE_BANNER_WITH_ID(),
          ROUTE_HINT_WITH_ID(),
          ROUTE_CHECKLIST_WITH_ID(),
          ROUTE_SPACE_WITH_ID(),
          ROUTE_RESOURCE_CENTER_WITH_ID(),
        ].includes(route)}
      />
      <div className={`bl-component-wrapper`}>
        <Component {...props} />
      </div>
    </div>
  );
};

export const EmptyLayout = ({component: Component, ...props}) => (
  <div className="empty-layout">
    <Component {...props} />
  </div>
);

export const OnboardingLayout = ({component: Component, ...props}) => {
  const location = useLocation();

  const [playChildrenAnimationOut, setPlayChildrenAnimationOut] =
    useState(false);

  const [playAnimationOut, setPlayAnimationOut] = useState(null);
  const [playAnimationPTL, setPlayAnimationPTL] = useState(null);
  const [playSquareAnimationOut, setPlaySquareAnimationOut] = useState(false);

  const isInApp =
    location.state?.from === '/lightweight-builder/extension' ||
    location.state?.from?.startsWith('/lightweight-builder/poke');

  const classNames = classnames('onboarding-layout', {
    [`anim-${playAnimationOut}`]: playAnimationOut != null,
    'is-in-app': isInApp,
  });
  const isDetailsRoute = [
    ROUTE_ONBOARDING_DETAILS_STEP_1_1,
    ROUTE_ONBOARDING_DETAILS_STEP_1_2,
    ROUTE_ONBOARDING_DETAILS_STEP_2_1,
    ROUTE_ONBOARDING_DETAILS_STEP_2_2,
    ROUTE_ONBOARDING_DETAILS_STEP_2_3,
    ROUTE_ONBOARDING_DETAILS_STEP_ONBOARDING,
    ROUTE_ONBOARDING_DETAILS_STEP_3_1,
    ROUTE_ONBOARDING_DETAILS_JOIN,
  ].includes(props.match.path);
  const isRegisterRoute = [
    ROUTE_REGISTER,
    ROUTE_REGISTER_SETUP_PASSWORD,
    ROUTE_REGISTER_EMAIL_SENT,
  ].includes(props.match.path);
  const isLoginRoute = [ROUTE_LOGIN].includes(props.match.path);

  return (
    <div className={classNames}>
      <div
        className={classnames('main-wrapper', {
          expanded: isDetailsRoute === true,
          'anim-children-out': playAnimationOut === true,
          'anim-details-in':
            props.location.state != null &&
            props.location.state.playDetailsAnimationIn === true,
          'anim-in': [
            ROUTE_REGISTER,
            ROUTE_REGISTER_SETUP_PASSWORD,
            ROUTE_LOGIN,
          ].includes(props.match.path),
          'grid-a-b-a':
            props.match.path === ROUTE_ONBOARDING_DETAILS_STEP_1_1 ||
            props.match.path === ROUTE_ONBOARDING_DETAILS_STEP_1_2 ||
            props.match.path === ROUTE_ONBOARDING_DETAILS_STEP_2_1 ||
            props.match.path === ROUTE_ONBOARDING_DETAILS_STEP_ONBOARDING ||
            props.match.path === ROUTE_ONBOARDING_DETAILS_STEP_3_1 ||
            props.match.path === ROUTE_ONBOARDING_DETAILS_JOIN,
          'grid-a-b':
            props.match.path === ROUTE_ONBOARDING_DETAILS_STEP_2_2 ||
            props.match.path === ROUTE_ONBOARDING_DETAILS_STEP_2_3,
        })}>
        {isDetailsRoute === true && (
          <ProgressionTracker
            path={props.match.path}
            playAnimation={playAnimationPTL}></ProgressionTracker>
        )}
        <Component
          {...props}
          playAnimationOut={playChildrenAnimationOut}
          triggerPTLAnimation={(type, next) => {
            /** Trigger Progress Tracker Label Animation */
            setPlayAnimationPTL(type);
            setTimeout(() => {
              next();
            }, 600);
          }}
          triggerAnimationOut={({name, duration, next} = {}) => {
            setPlayChildrenAnimationOut(true);

            if (name != null) {
              setTimeout(() => {
                setPlayAnimationOut(name);
                setTimeout(() => {
                  next();
                }, 2200);
              }, duration);
            }
          }}
          triggerOnboardingEnd={(next) => {
            setPlayAnimationOut('onboarding-end');
            setTimeout(() => {
              next();
            }, 1500);
          }}
          triggerSquareAnimationOut={() => setPlaySquareAnimationOut(true)}
        />
        {isDetailsRoute === true && (
          <div
            className={classnames('onboarding-details-animation', {
              'animation-out': playSquareAnimationOut === true,
            })}>
            <iframe
              src="https://my.spline.design/untitledcopycopycopy-b9311e94ca70776a5ca2ae24de34df90"
              title="Onboarding Details Animation"
              frameborder="0"
            />
          </div>
        )}
      </div>
      {(isRegisterRoute === true ||
        (isLoginRoute === true && isInApp !== true)) && (
        <div
          className={classnames('testimonials-wrapper', {
            'is-exiting': playAnimationOut === 'from-register-to-details',
          })}>
          <RegisterTestimonials />
        </div>
      )}
    </div>
  );
};

export const getCustomizationFromTheme = (type, theme) => {
  if (theme == null) {
    return defaultCustomization;
  }
  if (type === TYPE_BANNER) {
    return {
      ...defaultCustomization,
      boostedPrimaryColor: theme.bannerPrimaryColor,
      boostedTextsColors: theme.bannerTextsColors,
      boostedTitleFontSize: theme.bannerTitleFontSize,
      ctaBorderRadius: theme.bannerCtaBorderRadius,
      ctaBackgroundColor: theme.bannerCtaBackgroundColor,
      ctaColor: theme.bannerCtaColor,
    };
  } else if (type === TYPE_HOTSPOT) {
    return {
      ...defaultCustomization,
      boostedPositionFlags: theme.hotspotPositionFlags,
      boostedTextsColors: theme.hotspotTextsColors,
      boostedPrimaryColor: theme.hotspotPrimaryColor,
      boostedSecondaryColor: theme.hotspotSecondaryColor,
      boostedRoundness: theme.hotspotRoundness,
      boostedContentFontSize: theme.hotspotContentFontSize,
      boostedTitleFontSize: theme.hotspotTitleFontSize,
      boostedDotStyle: theme.hotspotStyle,
      boostedSize: theme.hotspotSize,
      ctaBorderRadius: theme.hotspotCtaBorderRadius,
      ctaBackgroundColor: theme.hotspotCtaBackgroundColor,
      ctaColor: theme.hotspotCtaColor,
    };
  } else if (type === TYPE_SNIPPET) {
    return {
      ...defaultCustomization,
      boostedTextsColors: theme.snippetTextsColors,
      boostedPrimaryColor: theme.snippetPrimaryColor,
      boostedSecondaryColor: theme.snippetSecondaryColor,
      boostedRoundness: theme.snippetRoundness,
      boostedContentFontSize: theme.snippetContentFontSize,
      boostedTitleFontSize: theme.snippetTitleFontSize,
      boostedSize: theme.snippetSize,
      ctaBorderRadius: theme.snippetCtaBorderRadius,
      ctaBackgroundColor: theme.snippetCtaBackgroundColor,
      ctaColor: theme.snippetCtaColor,
    };
  } else if (type === TYPE_MODAL) {
    return {
      ...defaultCustomization,
      boostedTextsColors: theme.modalTextsColors,
      boostedPrimaryColor: theme.modalPrimaryColor,
      boostedSecondaryColor: theme.modalSecondaryColor,
      boostedRoundness: theme.modalRoundness,
      boostedContentFontSize: theme.modalContentFontSize,
      boostedTitleFontSize: theme.modalTitleFontSize,
      boostedSize: theme.modalSize,
      ctaBorderRadius: theme.modalCtaBorderRadius,
      ctaBackgroundColor: theme.modalCtaBackgroundColor,
      ctaColor: theme.modalCtaColor,
      boostedLightbox: theme.modalLightbox,
    };
  } else if (type === TYPE_TOOLTIP) {
    return {
      ...defaultCustomization,
      boostedPositionFlags:
        theme.tooltipPositionFlags ||
        defaultCustomization.boostedPositionFlags ||
        0,
      boostedTextsColors:
        theme.tooltipTextsColors || defaultCustomization.boostedTextsColors,
      boostedPrimaryColor:
        theme.tooltipPrimaryColor || defaultCustomization.boostedPrimaryColor,
      boostedSecondaryColor:
        theme.tooltipSecondaryColor ||
        defaultCustomization.boostedSecondaryColor,
      boostedRoundness:
        theme.tooltipRoundness || defaultCustomization.boostedRoundness,
      boostedContentFontSize:
        theme.tooltipContentFontSize ||
        defaultCustomization.boostedContentFontSize,
      boostedTitleFontSize:
        theme.tooltipTitleFontSize || defaultCustomization.boostedTitleFontSize,
      boostedSize: theme.tooltipSize || defaultCustomization.boostedSize,
      ctaBorderRadius: theme.tooltipCtaBorderRadius,
      ctaBackgroundColor: theme.tooltipCtaBackgroundColor,
      ctaColor: theme.tooltipCtaColor,
      boostedLightbox:
        theme.tooltipLightbox || defaultCustomization.boostedLightbox,
    };
  } else if (type === TYPE_HINT) {
    return {
      ...defaultCustomization,
      boostedPositionFlags: theme.hotspotPositionFlags,
      boostedTextsColors: theme.hotspotTextsColors,
      boostedPrimaryColor: theme.hotspotPrimaryColor,
      boostedSecondaryColor: theme.hotspotSecondaryColor,
      boostedRoundness: theme.hotspotRoundness,
      boostedContentFontSize: theme.hotspotContentFontSize,
      boostedTitleFontSize: theme.hotspotTitleFontSize,
      boostedDotStyle: theme.hotspotStyle,
      boostedSize: theme.hotspotSize,
      ctaBorderRadius: theme.hotspotCtaBorderRadius,
      ctaBackgroundColor: theme.hotspotCtaBackgroundColor,
      ctaColor: theme.hotspotCtaColor,
    };
  } else {
    return defaultCustomization;
  }
};

export const PokeBuilderLayout = ({component: Component, ...props}) => {
  const logger = new Swaler('PokeBuilderLayout');

  const {parentTabRef, childTabIdRef, handshakeDataRef} =
    useContext(GlobalContext);

  const {match} = props;

  const history = useHistory();
  const location = useLocation();

  const queryString = qs.parse(location.search);
  const isTourGenerationBuilder =
    queryString?.['is-tour-generation'] === 'true';

  let defaultOptionsFlags = evolutionService.getDefaultOptionsFlags();

  const defaultEvolution = evolutionService.getDefaultEvolution({
    optionsFlags: defaultOptionsFlags,
    ...defaultCustomization,
    boostFlags: F_BOOST_SLOT_TOUR,
    type: match.params.type?.toUpperCase(),
  });
  const [originalEvolution, setOriginalEvolution] = useState(defaultEvolution);
  const [evolution, setEvolution] = useState(defaultEvolution);
  const [stepErrors, setStepErrors] = useState({});
  const [customization, setCustomization] = useState(defaultCustomization);
  const [selectedStepId, setSelectedStepId] = useState(null);
  const [selectedTourStepId, setSelectedTourStepId] = useState(null);
  const [showModalCta, setShowModalCta] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedBlockType, setSelectedBlockType] = useState(null);
  const [isTranslating, setIsTranslating] = useState(false);
  const [messenger, setMessenger] = useState(null);
  const [isEditingTargetElement, setIsEditingTargetElement] = useState(false);
  const [inConcept, setInConcept] = useState(null); // stepId|prototypeStepId - Ids of step holding the concept and prototype step (used by poke renderer)
  const [mode, setMode] = useState(MODE_NAVIGATOR);
  const [inAppForegroundForce, setInAppForegroundForce] = useState(false);
  const [currentUrl, setCurrentUrl] = useState(null);
  const [isInInteractivePreview, setIsInInteractivePreview] = useState(false);
  const [lastAutoSaveAt, setLastAutoSaveAt] = useState(null);
  const [previewedPreset, setPreviewedPreset] = useState(null);
  const [selectedTriggerId, setSelectedTriggerId] = useState(null);
  const [
    selectingElementTriggerConditionId,
    setSelectingElementTriggerConditionId,
  ] = useState(null);
  const [isDraggingToAddStep, setIsDraggingToAddStep] = useState(false);
  const [selectedSubItemId, setSelectedSubItemId] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [isSelectingPreview, setIsSelectingPreview] = useState(false);
  const [savedDraft, setSavedDraft] = useState(null);
  const [isAutoSaving, setIsAutoSaving] = useState(false);
  const [isTourGeneration, setIsTourGeneration] = useState(
    isTourGenerationBuilder
  );

  const {isFetching, refetch} = useFetchOrCreateEvolution({
    evolutionId: match?.params?.evolutionId,
    templateId: queryString?.template,
    tagId: queryString?.tag,
    defaultEvolution,
    onFetched(e) {
      if (e == null) {
        return;
      }
      e.tourSteps?.forEach((t) => {
        const [tourIndexOrder] = (t.tourStepInfo || '0;0;0').split(';');
        t.tourIndexOrder = parseInt(tourIndexOrder, 10);
        t.steps?.sort((a, b) => a.indexOrder - b.indexOrder);
      });
      e.tourSteps?.sort((a, b) => a.tourIndexOrder - b.tourIndexOrder);

      setOriginalEvolution(() => ({
        ...e,
        eventId: e.event?.uid,
      }));
      setEvolution(() => ({
        ...e,
        eventId: e.event?.uid,
      }));
      setSavedDraft(() => ({
        ...e,
        eventId: e.event?.uid,
      }));
      setCustomization({
        boostedPrimaryColor: e.boostedPrimaryColor,
        boostedSecondaryColor: e.boostedSecondaryColor,
        boostedTextsColors: e.boostedTextsColors,
        boostedRoundness: e.boostedRoundness,
        boostedTitleFontSize: e.boostedTitleFontSize,
        boostedContentFontSize: e.boostedContentFontSize,
        boostedDotStyle: e.boostedDotStyle,
        boostedSize: e.boostedSize,
        boostedLightbox: e.boostedLightbox,
        boostedAnimations: e.boostedAnimations,
        boostedZIndex: e.boostedZIndex,
      });
      setStepErrors(
        e.steps.reduce((acc, cur) => {
          acc[cur] = true;
          return acc;
        }, {})
      );
      if (e.type === EVOLUTION_TYPE_TOUR && isLoading === true) {
        setMode(null);
      }
      setIsLoading(false);
    },
    onCreated(e) {
      setEvolution(e);
      setSavedDraft(e);
      setIsLoading(false);
    },
  });

  useEffect(() => {
    if (queryString?.stepId != null) {
      setSelectedStepId(queryString.stepId);
      history.replace({
        ...location,
        search: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Redirect to builder with evolution id after evolution get created
  useEffect(() => {
    if (
      match.params.evolutionId === 'new' &&
      isFetching === false &&
      evolution.uid != null
    ) {
      history.push(ROUTE_POKE_BUILDER_FROM_TYPE(evolution.uid, evolution.type));
    }
  }, [evolution, isFetching]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (
        document.visibilityState === 'visible' &&
        match.params.evolutionId !== 'new' &&
        JSON.stringify(originalEvolution) === JSON.stringify(evolution)
      ) {
        refetch();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [refetch, match.params.evolutionId, originalEvolution, evolution]);

  const tourStepId =
    selectedStepId != null
      ? evolution.tourSteps?.find((t) =>
          t.steps.some((s) => s.uid === selectedStepId)
        )?.uid
      : selectedTourStepId != null
      ? evolution.tourSteps?.find((t) => t.uid === selectedTourStepId)?.uid
      : null;
  const controlledEvolution =
    tourStepId != null
      ? evolution.tourSteps.find((s) => s.uid === tourStepId)
      : evolution;
  const setControlledEvolution = (updatedEvolution) => {
    if (tourStepId != null) {
      setEvolution({
        ...evolution,
        tourSteps: evolution.tourSteps.map((s) => {
          if (s.uid === tourStepId) {
            return updatedEvolution;
          } else {
            return s;
          }
        }),
      });
    } else {
      setEvolution(updatedEvolution);
    }
  };
  const selectedStep =
    controlledEvolution?.steps.find((s) => s.uid === selectedStepId) ||
    controlledEvolution?.steps
      .map((s) => s.prototypes?.[0]?.steps)
      .flat()
      .find((s) => s?.uid === selectedStepId);
  const updateStep = (stepId, stepUpdate) => {
    setControlledEvolution({
      ...controlledEvolution,
      steps: controlledEvolution.steps.map((s) => {
        if (s.uid === stepId) {
          return {...s, ...stepUpdate};
        } else {
          return {
            ...s,
            prototypes: s.prototypes?.map((p) => ({
              ...p,
              steps: p.steps?.map((ps) =>
                ps.uid === stepId ? {...ps, ...stepUpdate} : ps
              ),
            })),
          };
        }
      }),
    });
  };
  const updateBlock = (blockType, updateObj, opts) => {
    const {style, ...rest} = updateObj;

    updateStep(selectedStep.uid, {
      blocks: selectedStep.blocks.map((block) => {
        if (opts != null) {
          if (opts.parentBlockId != null) {
            if (
              block.type === blockType &&
              block.parentBlockId === opts.parentBlockId
            ) {
              return {
                ...block,
                ...rest,
                style: {
                  ...block.style,
                  ...style,
                },
              };
            } else {
              return block;
            }
          }

          if (opts.blockId != null) {
            if (block.uid === opts.blockId) {
              return {
                ...block,
                ...rest,
                style: {
                  ...block.style,
                  ...style,
                },
              };
            }
          }

          return block;
        } else if (block.type === blockType) {
          return {
            ...block,
            ...rest,
            style: {
              ...block.style,
              ...style,
            },
          };
        } else {
          return block;
        }
      }),
    });

    // uncomment if we want to reset theme when updating style

    // if (updateObj.style != null) {
    //   setEvolution((prevState) => ({
    //     ...prevState,
    //     themeId: null,
    //     theme: null,
    //   }));
    // }
  };

  const smartTranslate = async (languages) => {
    setIsTranslating(true);
    const toTranslate = getTextToTranslate(evolution);

    try {
      let translations = await openaiService.translate({
        text: JSON.stringify(toTranslate),
        languages: languages.map((l) => l.label),
      });
      if (typeof translations === 'string') {
        try {
          // Try to parse the string as JSON
          translations = JSON.parse(translations);
        } catch (error) {
          console.error('Parsing error:', error);
          // Handle parsing error, e.g., data might not be a valid JSON string
        }
      }
      const translatedEvolution = {...evolution};
      for (const language of Object.keys(translations)) {
        const foundLanguage = languages.find(
          (l) => l.label.toLowerCase() === language?.toLowerCase()
        );
        const languageCode = foundLanguage?.code;
        if (languageCode != null) {
          setTranslatedTextToEvolution(
            languageCode,
            evolution,
            translations[language]
          );
        }
      }
      setEvolution(translatedEvolution);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Smart translate failed with error ', code);
      toastDanger([title, message], {actions});
    } finally {
      setIsTranslating(false);
    }
  };

  const isInApp = [
    ROUTE_LIGHTWEIGHT_POKE_BUILDER,
    ROUTE_LIGHTWEIGHT_POKE_BUILDER_WITH_ID(),
  ].includes(match.path);

  const {openNewTab} = useAcrossTabs({
    onHandshakeCallback: () => {
      sendSetParentTab();

      parentTabRef.current?.broadCastTo(
        childTabIdRef.current,
        handshakeDataRef.current
      );
    },
  });

  const {
    openInAppBuilderAt,
    isEditingInApp,
    launchBuilderModalData,
    closeLaunchBuilderModal,
    goToEditInApp,
    stopInAppEditing,
  } = useInAppBuilder({openNewTab});

  const isTour = evolution.type === EVOLUTION_TYPE_TOUR;
  const isSurvey = evolution.type === EVOLUTION_TYPE_SURVEY;
  const isCompatibleWithLogicView = isLogicViewCompatible(evolution);

  const showDeprecatedBanner =
    (isTour || isSurvey) && isCompatibleWithLogicView !== true;

  return (
    <BuilderContext.Provider
      value={{
        originalEvolution,
        setOriginalEvolution,
        evolution,
        setEvolution,
        stepErrors,
        setStepErrors,
        customization,
        setCustomization,
        selectedStepId,
        setSelectedStepId,
        hasUnSaveChanges: () => {
          return (
            JSON.stringify(evolution) !== JSON.stringify(originalEvolution)
          );
        },
        showModalCta,
        setShowModalCta,
        refetchEvolution: refetch,
        selectedBlockType,
        setSelectedBlockType,
        controlledEvolution,
        setControlledEvolution,
        selectedTourStepId,
        setSelectedTourStepId,
        smartTranslate,
        isTranslating,
        selectedStep,
        updateStep,
        updateBlock,
        messenger,
        setMessenger,
        isInApp,
        isEditingTargetElement,
        setIsEditingTargetElement,
        inConcept,
        setInConcept,
        mode,
        setMode,
        inAppForegroundForce,
        setInAppForegroundForce,
        currentUrl,
        setCurrentUrl,
        isInInteractivePreview,
        setIsInInteractivePreview,
        isAutoSaving,
        setIsAutoSaving,
        lastAutoSaveAt,
        setLastAutoSaveAt,
        isBoosted: evolution?.isBoostOf != null,
        previewedPreset,
        setPreviewedPreset,
        selectedTriggerId,
        setSelectedTriggerId,
        selectingElementTriggerConditionId,
        setSelectingElementTriggerConditionId,
        isDraggingToAddStep,
        setIsDraggingToAddStep,
        selectedSubItemId,
        setSelectedSubItemId,
        selectedLanguage,
        setSelectedLanguage,
        isSelectingPreview,
        setIsSelectingPreview,
        savedDraft,
        setSavedDraft,
        isTourGeneration,
        setIsTourGeneration,
      }}>
      <InAppBuilderContext.Provider
        value={{
          openInAppBuilderAt,
          isEditingInApp,
          launchBuilderModalData,
          closeLaunchBuilderModal,
          goToEditInApp,
          stopInAppEditing,
        }}>
        <ErrorBoundary FallbackComponent={PokeBuilderEB}>
          <div
            className={classnames('poke-builder-layout', {
              transparent: isInApp,
            })}>
            {isLoading !== true &&
              isEditingTargetElement !== true &&
              isSelectingPreview !== true &&
              isTourGeneration !== true && (
                <PokeBuilderHeader
                  isFetching={isFetching}
                  isLoading={isLoading}
                  isInApp={isInApp}
                />
              )}
            <div className="poke-builder-component">
              {isLoading === true ? (
                <div className="builder-component-loader-wrapper">
                  <div className="loader-wrapper">
                    <Loader />
                    <span>Loading</span>
                  </div>
                </div>
              ) : (
                <Component />
              )}
            </div>
            {showDeprecatedBanner && (
              <div className="deprecated-builder-indicator">
                <div className="deprecated-builder-content subtitle-4">
                  <span className="o-500">Notice:</span>
                  <span>
                    This experience is deprecated. You can edit it using the
                    legacy builder.
                  </span>
                  <span
                    className="b-400 learn-more"
                    onClick={() => {
                      window.open(
                        'https://help.usejimo.com/help-center',
                        '_blank'
                      );
                    }}>
                    learn more
                  </span>
                </div>
              </div>
            )}
          </div>

          {launchBuilderModalData != null && (
            <ModalLaunchBuilder
              isOpen={launchBuilderModalData != null}
              onRequestClose={() => {
                closeLaunchBuilderModal();
              }}
              onOpenUrl={(url) => openInAppBuilderAt(url)}
              data={launchBuilderModalData}
            />
          )}
        </ErrorBoundary>
      </InAppBuilderContext.Provider>
    </BuilderContext.Provider>
  );
};

export const PostBuilderLayout = ({component: Component, ...props}) => {
  const logger = new Swaler('PostBuilderLayout');

  const project = useSelector((state) => generalSelector.getProject(state));

  const {match} = props;

  const history = useHistory();

  let defaultOptionsFlags = evolutionService.getDefaultOptionsFlags();
  defaultOptionsFlags = addFlag(
    [F_OPTION_PORTAL_DISPLAY_FEED, F_OPTION_SHOW_ON_PORTAL],
    defaultOptionsFlags
  );

  const defaultEvolution = evolutionService.getDefaultEvolution({
    optionsFlags: defaultOptionsFlags,
    context: EVOLUTION_CONTEXT_PORTAL,
    type: EVOLUTION_TYPE_POST,
    steps: [
      getDefaultStep({
        name: 'New step',
        blocks: [
          {
            ...getDefaultBlockFromType(BLOCK_TYPE_TITLE),
            value: '',
          },
          {
            ...getDefaultBlockFromType(BLOCK_TYPE_BODY),
            value: '',
          },
        ],
      }),
    ],
  });
  const [originalEvolution, setOriginalEvolution] = useState(defaultEvolution);
  const [evolution, setEvolution] = useState(defaultEvolution);
  const [isLoading, setIsLoading] = useState(true);
  const [isTranslating, setIsTranslating] = useState(false);
  const [mode, setMode] = useState(MODE_NAVIGATOR);
  const [lastAutoSaveAt, setLastAutoSaveAt] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [projectToUpdate, setProjectToUpdate] = useState(project);
  const [changelogTheme, setChangelogTheme] = useState(
    project.changelogStyle ?? defaultChangelogStyle
  );

  const isSetupChangelog = [
    ROUTE_CHANGELOG_BUILDER,
    ROUTE_CHANGELOG_BUILDER_WITH_ID(),
  ].includes(match.path);
  const isPostWriter = match.path === ROUTE_POST_BUILDER_WRITER();

  const {isFetching, refetch} = useFetchOrCreateEvolution({
    evolutionId: match?.params?.evolutionId,
    defaultEvolution,
    onFetched(e) {
      if (e == null) {
        return;
      }
      setOriginalEvolution(() => ({
        ...e,
        eventId: e.event?.uid,
      }));
      setEvolution(() => ({
        ...e,
        eventId: e.event?.uid,
      }));
      setIsLoading(false);
    },
    onCreated(e) {
      setEvolution(e);
      setIsLoading(false);
    },
    disabled: isPostWriter !== true && match?.params?.evolutionId == null,
  });

  // Redirect to builder with evolution id after evolution get created
  useEffect(() => {
    if (
      match.params.evolutionId === 'new' &&
      isFetching === false &&
      evolution.uid != null
    ) {
      return history.push(ROUTE_POST_BUILDER_WRITER(evolution.uid));
    }
  }, [evolution, isFetching]);

  const smartTranslate = async (languages) => {
    setIsTranslating(true);
    const toTranslate = getTextToTranslate(evolution);

    try {
      const translations = await openaiService.translate({
        text: JSON.stringify(toTranslate),
        languages: languages.map((l) => l.label),
      });
      const translatedEvolution = {...evolution};
      for (const language of Object.keys(translations)) {
        const foundLanguage = languages.find((l) => l.label === language);
        const languageCode = foundLanguage?.code;
        if (languageCode != null) {
          setTranslatedTextToEvolution(
            languageCode,
            evolution,
            translations[language]
          );
        }
      }
      setEvolution(translatedEvolution);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Smart translate failed with error ', code);
      toastDanger([title, message], {actions});
    } finally {
      setIsTranslating(false);
    }
  };

  return (
    <BuilderContext.Provider
      value={{
        originalEvolution,
        setOriginalEvolution,
        evolution,
        setEvolution,
        hasUnSaveChanges: () => {
          return (
            JSON.stringify(evolution) !== JSON.stringify(originalEvolution)
          );
        },
        refetchEvolution: refetch,
        smartTranslate,
        isTranslating,
        mode,
        setMode,
        lastAutoSaveAt,
        setLastAutoSaveAt,
        selectedLanguage,
        setSelectedLanguage,
        changelogTheme,
        setChangelogTheme,
        project: projectToUpdate,
        setProject: setProjectToUpdate,
      }}>
      <ErrorBoundary FallbackComponent={PokeBuilderEB}>
        <div className={classnames('post-builder-layout')}>
          <PostBuilderHeader isFetching={isFetching} isLoading={isLoading} />
          <div className="post-builder-component">
            {isLoading === true && isPostWriter ? (
              <div className="builder-component-loader-wrapper">
                <div className="loader-wrapper">
                  <Loader />
                  <span>Loading</span>
                </div>
              </div>
            ) : (
              <Component />
            )}
          </div>
        </div>
      </ErrorBoundary>
    </BuilderContext.Provider>
  );
};

export const LightweightBuilderLayout = ({component: Component, ...props}) => {
  const {match} = props;

  const history = useHistory();
  const location = useLocation();

  const queryString = qs.parse(location.search);

  const defaultEvolution = evolutionService.getDefaultEvolution();

  const [originalEvolution, setOriginalEvolution] = useState(defaultEvolution);
  const [evolution, setEvolution] = useState(defaultEvolution);
  const [stepErrors, setStepErrors] = useState({});
  const [customization, setCustomization] = useState({
    boostedPrimaryColor: '#ffffff',
    boostedSecondaryColor: '#1260EB',
    boostedTextsColors: '#071331;#071331',
    boostedRoundness: '8',
    boostedTitleFontSize: '18',
    boostedContentFontSize: '14',
    boostedDotStyle: `#1260EB;22;${HOTSPOT_SHAPE_DEFAULT}`,
    boostedSize: null,
    boostedLightbox: 'SOFT',
    boostedAnimations: 'slide;slide;left;left',
    boostedZIndex: null,
  });
  const [selectedStepId, setSelectedStepId] = useState(null);
  const [selectedTourStepId, setSelectedTourStepId] = useState(null);
  const [showModalCta, setShowModalCta] = useState(false);

  const {isFetching} = useFetchOrCreateEvolution({
    evolutionId: match?.params?.evolutionId,
    templateId: queryString?.template,
    tagId: queryString?.tag,
    defaultEvolution: {
      ...defaultEvolution,
    },
    onFetched(e) {
      if (e == null) {
        return;
      }
      setOriginalEvolution(() => ({
        ...e,
        eventId: e.event?.uid,
      }));
      setEvolution(() => ({
        ...e,
        eventId: e.event?.uid,
      }));
      setCustomization({
        boostedPrimaryColor: e.boostedPrimaryColor,
        boostedSecondaryColor: e.boostedSecondaryColor,
        boostedTextsColors: e.boostedTextsColors,
        boostedRoundness: e.boostedRoundness,
        boostedTitleFontSize: e.boostedTitleFontSize,
        boostedContentFontSize: e.boostedContentFontSize,
        boostedDotStyle: e.boostedDotStyle,
        boostedSize: e.boostedSize,
        boostedLightbox: e.boostedLightbox,
        boostedAnimations: e.boostedAnimations,
        boostedZIndex: e.boostedZIndex,
      });
      setStepErrors(
        e.steps.reduce((acc, cur) => {
          acc[cur] = true;
          return acc;
        }, {})
      );
    },
    onCreated(e) {
      setEvolution(e);
    },
  });

  // Redirect to builder with evolution id after evolution get created
  useEffect(() => {
    if (
      match.params.evolutionId === 'new' &&
      isFetching === false &&
      evolution.uid != null
    ) {
      return history.push(
        ROUTE_POST_BUILDER_WRITER({evolutionId: evolution.uid})
      );
    }
  }, [evolution, isFetching]);

  // Hide Crisp
  useEffect(() => {
    if (window.$crisp != null) {
      window.$crisp.push(['do', 'chat:hide']);
    }
  });

  return (
    <BuilderContext.Provider
      value={{
        originalEvolution,
        setOriginalEvolution,
        evolution,
        setEvolution,
        stepErrors,
        setStepErrors,
        customization,
        setCustomization,
        selectedStepId,
        setSelectedStepId,
        selectedTourStepId,
        setSelectedTourStepId,
        hasUnSaveChanges: () =>
          JSON.stringify(evolution) !== JSON.stringify(originalEvolution),
        showModalCta,
        setShowModalCta,
      }}>
      <div className="lightweight-builder-layout">
        <div className="builder-component">
          {isFetching === true ? (
            <div className="builder-component-overlay fade-in">
              <Loader width={15} />
              Loading
            </div>
          ) : (
            <Component />
          )}
        </div>
      </div>
    </BuilderContext.Provider>
  );
};

const SettingsLayoutWithRouter = ({component: Component, ...props}) => {
  const classNames = classnames('settings-layout');

  return (
    <div className={classNames}>
      <div className="settings-navbar-wrapper">
        <Navbar compact />
        <NavbarSettings />
      </div>
      <div
        className={classnames('settings-content', {
          'no-padding': props.match.path === ROUTE_SETTINGS_THEMES_ID(),
          'no-bottom-padding':
            props.match.path === ROUTE_SETTINGS_INTEGRATIONS_WEBHOOK_DETAILS(),
        })}>
        <Component {...props} />
      </div>
    </div>
  );
};

export const SettingsLayout = withRouter(SettingsLayoutWithRouter);

export const TemplatesLayout = ({component: Component, ...props}) => {
  return (
    <div className="templates-layout">
      <div className="templates-navbar-wrapper">
        <Navbar compact />
      </div>
      <div className="templates-content">
        <Component {...props} />
      </div>
    </div>
  );
};
