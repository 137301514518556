import {addFlag} from 'helpers/bitwise';
import {getDefaultBlockFromType} from 'scenes/PokeBuilder/components/BlockManager/utils';
import {getBoostFlags} from 'scenes/Pushes/components/ModalCreatePoke';
import {
  TYPE_CURSOR,
  TYPE_TOOLTIP,
} from 'scenes/Pushes/components/ModalCreatePoke/components/TemplatesModal/templates';
import {evolutionService} from 'services';
import {
  defaultCustomization,
  EVOLUTION_TYPE_TOUR,
  F_OPTION_POKE_CARD_WITH_POINTER,
  F_OPTION_PROGRESS_ON_TARGET_CLICK,
  getDefaultOptionsFlags,
} from 'services/evolution';
import {
  BLOCK_TYPE_BODY,
  BLOCK_TYPE_CURSOR,
  BLOCK_TYPE_DISMISS_CROSS,
  BLOCK_TYPE_PRIMARY_CTA,
  getDefaultStep,
  getDefaultStepStyle,
} from 'services/steps';
import {v4 as uuidv4} from 'uuid';

export const generateEvolutionFromSteps = (steps, elements) => {
  let defaultOptionsFlags = evolutionService.getDefaultOptionsFlags();

  const generatedEvolution = evolutionService.getDefaultEvolution({
    uid: uuidv4(),
    optionsFlags: defaultOptionsFlags,
    ...defaultCustomization,
    type: EVOLUTION_TYPE_TOUR,
    tourSteps: steps.map((step, index) => {
      const foundElement = elements.find(
        (element) => element.defaultSelector === step.elementSelector
      );
      const isClickable =
        foundElement?.elementInfo?.interaction?.isClickable === true;

      const {stepsBefore, stepsAfter} = steps.reduce(
        (acc, cur, curIndex) => {
          if (curIndex < index) {
            acc.stepsBefore = acc.stepsBefore + 1;
          } else if (curIndex > index) {
            acc.stepsAfter = acc.stepsAfter + 1;
          }
          return acc;
        },
        {stepsBefore: 0, stepsAfter: 0}
      );

      let optionsFlags = getDefaultOptionsFlags();
      let type = null;

      if (isClickable === true) {
        type = TYPE_CURSOR;
        optionsFlags = addFlag(optionsFlags, F_OPTION_PROGRESS_ON_TARGET_CLICK);
      } else {
        type = TYPE_TOOLTIP;
        optionsFlags = addFlag(optionsFlags, F_OPTION_POKE_CARD_WITH_POINTER);
      }

      const tourStep = evolutionService.getDefaultEvolution({
        uid: uuidv4(),
        boostFlags: getBoostFlags(type),
        steps: [
          getDefaultStep({
            name: `Step ${index + 1}`,
            blocks: [
              ...(type === TYPE_CURSOR
                ? [getDefaultBlockFromType(BLOCK_TYPE_CURSOR)]
                : []),
              {
                ...getDefaultBlockFromType(BLOCK_TYPE_BODY),
                value: step.stepText,
                rawValue: [
                  {
                    object: 'block',
                    type: 'paragraph',
                    children: [
                      {
                        object: 'text',
                        text: step.stepText,
                      },
                    ],
                    data: {},
                  },
                ],
              },
              {
                ...getDefaultBlockFromType(BLOCK_TYPE_DISMISS_CROSS),
                style: {
                  ...getDefaultBlockFromType(BLOCK_TYPE_DISMISS_CROSS).style,
                  ...(type === TYPE_CURSOR
                    ? {
                        fontSize: 12,
                      }
                    : {}),
                },
              },
              ...(type === TYPE_TOOLTIP
                ? [getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA)]
                : []),
            ],
            style: {
              ...getDefaultStepStyle(),
              paddingTop: 12,
              paddingBottom: 12,
              paddingLeft: 12,
              paddingRight: 12,
              gap: 8,
            },
          }),
        ],
        tourStepInfo: `${index};${stepsBefore};${stepsAfter}`,
        boostedQueryselector: step.elementSelector,
        style: getDefaultStepStyle(),
        optionsFlags: optionsFlags,
      });

      return tourStep;
    }),
  });

  return generatedEvolution;
};
