import classNames from 'classnames';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import {toastDanger, toastSuccess} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {isActiveOnDomain} from 'helpers/utils';
import {func, object, string} from 'prop-types';
import React, {useState} from 'react';
import AnimateHeight from 'react-animate-height';
import {useQuery} from 'react-query';
import {environmentService, evolutionService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';
import Environments from './imgs/environments.png';

const propTypes = {
  evolution: object,
  refetch: func,
  trigger: string,
  dropdownProps: object,
  btnProps: object,
  headerLabel: string,
  btnLabel: string,
};

const logger = new Swaler('EnvironmentSelector');

const EnvironmentSelector = ({
  evolution,
  trigger,
  refetch = () => {},
  dropdownProps = {},
  btnProps = {},
  headerLabel,
  btnLabel,
}) => {
  const currentEnvironments = evolution.environments || [];
  const isPublishedToAllDomains =
    currentEnvironments.length === 0 && evolution.isDraft !== true;

  const [selectedEnvironments, setSelectedEnvironments] =
    useState(currentEnvironments);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isDomainDetailsOpen, setIsDomainDetailsOpen] = useState(false);

  const {data: environments = []} = useQuery({
    queryKey: 'environments',
    queryFn: () => environmentService.getEnvironments({}),
    onError: (err) => {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Failed to fetch environments with error', code);
      return toastDanger([title, message], {actions});
    },
  });

  const handleUpdateEnvironments = async () => {
    setIsUpdating(true);
    try {
      await evolutionService.updateEvolutionEnvironments(
        evolution.uid,
        selectedEnvironments
      );

      refetch();
      setIsOpen(false);
      toastSuccess('Environments updated successfully');
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Failed to update environments with error', code);
      return toastDanger([title, message], {actions});
    } finally {
      setIsUpdating(false);
    }
  };

  const selectedEnvironmentsUids = selectedEnvironments
    ?.map((e) => e.uid)
    ?.sort((a, b) => a - b);
  const existingEnvironmentsUids = (evolution.environments || [])
    ?.map((e) => e.uid)
    ?.sort((a, b) => a - b);

  const isUpdateDisabled =
    JSON.stringify(selectedEnvironmentsUids) ===
    JSON.stringify(existingEnvironmentsUids);

  const iconWrappersWidth =
    Math.min(currentEnvironments.length - 1, 2) * 12 +
    (currentEnvironments.length > 0 ? 24 : 0);

  const allSelectedDomains = Array.from(
    new Set(
      selectedEnvironments
        .map((env) => env.domains?.split(';').filter((d) => d))
        .flat()
        .filter((d) => d)
    )
  );

  const activeDomains = allSelectedDomains.filter(
    (domain) =>
      isActiveOnDomain(evolution.boostedDomainFilter, domain).isActive === true
  );

  const negativeDomains = allSelectedDomains.filter((domain) =>
    domain.startsWith('!')
  );

  const sortedDomains = allSelectedDomains.sort((a, b) => {
    const aIsActive = activeDomains.includes(a);
    const bIsActive = activeDomains.includes(b);
    const aIsNegative = negativeDomains.includes(a);
    const bIsNegative = negativeDomains.includes(b);

    if (aIsNegative === true && bIsNegative === true) {
      return 0;
    }
    if (aIsNegative === true) {
      return 1;
    }
    if (bIsNegative === true) {
      return -1;
    }
    return aIsActive === bIsActive ? 0 : aIsActive ? -1 : 1;
  });

  return (
    <Dropdown
      className="environment-selector-wrapper"
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
        setIsDomainDetailsOpen(false);
      }}
      trigger={
        trigger != null ? (
          trigger()
        ) : (
          <Button
            className={classNames('environment-selector-btn', {
              'is-open': isOpen,
            })}
            thin
            iconLeft={
              currentEnvironments.length === 0 ? 'isax isax-global' : null
            }
            iconRight="icon-chevron-bottom n-400"
            onClick={() => setIsOpen(true)}>
            {currentEnvironments?.length > 1 ? (
              <>
                <div
                  className="icons-wrapper"
                  style={{
                    width: `${iconWrappersWidth}px`,
                  }}>
                  {currentEnvironments?.map((env, index) => {
                    const {icon, color} = env;
                    return (
                      <div
                        key={env.uid}
                        className={classNames('icon-wrapper', `index-${index}`)}
                        style={{backgroundColor: color}}>
                        {icon}
                      </div>
                    );
                  })}
                </div>
                <div className="label body-3">
                  {currentEnvironments[0].name} &{' '}
                  {currentEnvironments.length - 1} environment
                  {currentEnvironments.length > 2 ? 's' : ''}
                </div>
              </>
            ) : currentEnvironments?.length === 1 ? (
              <>
                <div
                  className="icons-wrapper"
                  style={{
                    width: `${iconWrappersWidth}px`,
                  }}>
                  <div
                    className="icon-wrapper"
                    style={{backgroundColor: currentEnvironments[0].color}}>
                    {currentEnvironments[0].icon}
                  </div>
                </div>
                <div className="label body-3">
                  {currentEnvironments[0].name}
                </div>
              </>
            ) : (
              <div className="label body-3">All domains</div>
            )}
          </Button>
        )
      }
      position="bottom"
      offsetY={8}
      {...dropdownProps}>
      <div className="environment-selector-content">
        <div className="environment-selector-header subtitle-4 n-800">
          {headerLabel || 'Select environment(s)'}
        </div>
        <div className="environment-selector-center">
          <div
            className={classNames('environment-option', {
              selected: selectedEnvironments?.length === 0,
              disabled: environments.length === 0,
            })}
            onClick={() => {
              setSelectedEnvironments([]);
            }}>
            <div className="environment-icon all-domains">
              <i className="isax isax-global n-700" />
            </div>
            <div className="environment-info">
              <div className="environment-name body-3">All domains</div>
            </div>
            <div className="environment-checkbox">
              {selectedEnvironments?.length === 0 ? (
                <i className="icon-checkbox-a" />
              ) : (
                <i className="icon-checkbox-o" />
              )}
            </div>
          </div>
          {environments.length === 0 && (
            <div className="no-environments body-4 n-600">
              <img src={Environments} alt="No environments" />
              <div>
                Create your first environment in
                <br />
                <a
                  className="b-400"
                  href="/settings/environments"
                  target="_blank"
                  rel="noreferrer">
                  Settings {'>'} Environments
                </a>
              </div>
            </div>
          )}
          {environments
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((environment) => {
              const {uid, name, icon, color, domains} = environment;

              const isSelected = selectedEnvironments?.some(
                (e) => e.uid === uid
              );

              const domainsArr = domains?.split(';')?.filter((d) => d);

              return (
                <div
                  key={uid}
                  className={classNames('environment-option', {
                    selected: isSelected,
                  })}
                  onClick={() => {
                    setSelectedEnvironments((prev) =>
                      isSelected
                        ? prev.filter((env) => env.uid !== environment.uid)
                        : [...prev, environment]
                    );
                  }}>
                  <div
                    className="environment-icon"
                    style={{backgroundColor: color}}>
                    {icon}
                  </div>
                  <div className="environment-info">
                    <div className="environment-name body-3">{name}</div>
                    <div className="environment-domains body-4 n-600">
                      {domainsArr?.length > 1
                        ? `${domainsArr.length} domains`
                        : domainsArr?.length === 1
                        ? domainsArr[0]
                        : 'All domains'}
                    </div>
                  </div>
                  <div className="environment-checkbox">
                    {isSelected ? (
                      <i className="icon-checkbox-a" />
                    ) : (
                      <i className="icon-checkbox-o" />
                    )}
                  </div>
                </div>
              );
            })}
        </div>
        <div className="environment-selector-footer">
          <AnimateHeight
            duration={300}
            height={
              isDomainDetailsOpen === true || selectedEnvironments.length === 0
                ? 'auto'
                : 17
            }>
            <div
              className="domains-list-wrapper"
              onClick={() => {
                setIsDomainDetailsOpen(!isDomainDetailsOpen);
              }}>
              <div className="icon-wrapper">
                {isUpdateDisabled && evolution.isDraft !== true ? (
                  <i className="isax isax-tick-circle5 g-400" />
                ) : (
                  <i className="isax isax-global n-600" />
                )}
              </div>
              <div className="domains-list">
                {selectedEnvironments.length > 0 ? (
                  <>
                    {sortedDomains.map((domain, index) => {
                      const isDisabled =
                        isActiveOnDomain(evolution.boostedDomainFilter, domain)
                          .isActive !== true;
                      const isNegative = negativeDomains.includes(domain);

                      return (
                        <div
                          key={domain}
                          className={classNames('domain body-4 n-800', {
                            disabled: isDisabled,
                          })}>
                          {isNegative ? `Exclude ${domain.slice(1)}` : domain}
                          {isDomainDetailsOpen !== true &&
                            index === 0 &&
                            activeDomains.length > 1 && (
                              <span className="more-domains">
                                {' '}
                                +{activeDomains.length - 1}
                              </span>
                            )}
                          {isDomainDetailsOpen === true &&
                            index < activeDomains.length - 1 && <>,</>}
                        </div>
                      );
                    })}
                  </>
                ) : (
                  `Publish${
                    isPublishedToAllDomains === true ? 'ed' : ''
                  } to all domains where Jimo is installed`
                )}
              </div>
              {selectedEnvironments.length > 0 &&
                allSelectedDomains.length > 1 && (
                  <div
                    className={classNames('chevron-wrapper', {
                      'is-open': isDomainDetailsOpen,
                    })}>
                    <i className="icon-chevron-bottom n-600" />
                  </div>
                )}
            </div>
          </AnimateHeight>
          <Button
            primary
            thin
            className="update-btn"
            disabled={isUpdateDisabled}
            loading={isUpdating}
            {...btnProps}
            onClick={
              btnProps.onClick != null
                ? () => btnProps.onClick(selectedEnvironments)
                : handleUpdateEnvironments
            }>
            {btnLabel || 'Update'}
          </Button>
        </div>
      </div>
    </Dropdown>
  );
};

EnvironmentSelector.propTypes = propTypes;

export default EnvironmentSelector;
